import { DefaultTheme } from 'styled-components';

import { themeDefault } from './default';
import { themeEnergybooster } from './energybooster';
import { themeChangeyourself } from './changeyourself';
import { themeFastingplan } from './fastingplan';
import { themeBodymanagement } from './bodymanagement';
import { themeBodytransformation } from './bodytransformation';
import { ThemeName } from './name';
import { themeHealthydietfitness } from './healthydietfitness';
import { themeLoseyourweight } from './loseyourweight';
import { themeMealsplan } from './mealsplan';
import { themeBodyWellNess } from './bodywellness';
import { themeHealthWellNess } from './healthwellness';
import { themeFastingTrainer } from './fastingtrainer';
import { themeHealthyLifetime } from './healthylifetime';
import { themeYourBodywell } from './yourbodywell';
import { themeBodyCoach } from './bodycoach';
import { themeDietPlanners } from './dietplanners';
import { themeWeightCutting } from './weightcutting';
import { themeHealthyPlans } from './healthyplans';
import { themeEatHappyHealthy } from './eathappyhealthy';
import { themeGetShape } from './getshape';
import { themeHealhyCareOnline } from './healthycareonline';
import { themeEatingPro } from './eatingpro';
import { themePersonalizedMeals } from './personalizedmeals';
import { themeAmofit } from './amofit';
import { themeDietCoaches } from './dietcoaches';
import { themeFastingPro } from './fastingpro';
import { themeFeelGoodInc } from './feelgoodinc';
import { themeMealChallenge } from './mealchallenge';
import { themeHealthyMeals } from './healthymeals';
import { themeBodymaker } from './bodymaker';
import { themeBestWeight } from './bestweight';
import { themeBetterPlan } from './betterplan';
import { themeBodyimprover } from './bodyimprover';
import { themeImproveMe } from './improveme';
import { themeGetHealthy } from './gethealthy';
import { themeWeightDecrease } from './weightdecrease';
import { themeGoHungry } from './gohungry';
import { themeEatNothing } from './eatnothing';
import { themeBestSnack } from './bestsnack';
import { themeHealthyBites } from './healthybites';
import { themeRightShape } from './rightshape';
import { themePerfectBody } from './perfectbody';
import { themeDietMaker } from './dietmaker';
import { themeEnergyMonster } from './energymonster';
import { themeGainWeight } from './gainweight';
import { themeDietHacker } from './diethacker';
import { themeBodyGuard } from './bodyguard';
import { themeFitkeeper } from './fitkeeper';
import { themeHarnafit } from './harnafit';
import { themeHarnatech } from './harnatech';

const themes: Record<string, DefaultTheme> = {
  ...(!process.env.NEXT_PUBLIC_MIRROR_ID
    ? { [ThemeName.default]: themeDefault }
    : {}),
  ...(process.env.NEXT_PUBLIC_MIRROR_ID === 'energybooster'
    ? { [ThemeName.energybooster]: themeEnergybooster }
    : {}),
  ...(process.env.NEXT_PUBLIC_MIRROR_ID === 'changeyourself'
    ? { [ThemeName.changeyourself]: themeChangeyourself }
    : {}),
  ...(process.env.NEXT_PUBLIC_MIRROR_ID === 'fastingplan'
    ? { [ThemeName.fastingplan]: themeFastingplan }
    : {}),
  ...(process.env.NEXT_PUBLIC_MIRROR_ID === 'bodymanagement'
    ? { [ThemeName.bodymanagement]: themeBodymanagement }
    : {}),
  ...(process.env.NEXT_PUBLIC_MIRROR_ID === 'healthydietfitness'
    ? { [ThemeName.healthydietfitness]: themeHealthydietfitness }
    : {}),
  ...(process.env.NEXT_PUBLIC_MIRROR_ID === 'loseyourweight'
    ? { [ThemeName.loseyourweight]: themeLoseyourweight }
    : {}),
  ...(process.env.NEXT_PUBLIC_MIRROR_ID === 'bodytransformation'
    ? { [ThemeName.bodytransformation]: themeBodytransformation }
    : {}),
  ...(process.env.NEXT_PUBLIC_MIRROR_ID === 'mealsplan'
    ? { [ThemeName.mealsplan]: themeMealsplan }
    : {}),
  ...(process.env.NEXT_PUBLIC_MIRROR_ID === 'bodywellness'
    ? { [ThemeName.bodywellness]: themeBodyWellNess }
    : {}),
  ...(process.env.NEXT_PUBLIC_MIRROR_ID === 'healthwellness'
    ? { [ThemeName.healthwellness]: themeHealthWellNess }
    : {}),
  ...(process.env.NEXT_PUBLIC_MIRROR_ID === 'fastingtrainer'
    ? { [ThemeName.fastingtrainer]: themeFastingTrainer }
    : {}),
  ...(process.env.NEXT_PUBLIC_MIRROR_ID === 'healthylifetime'
    ? { [ThemeName.healthylifetime]: themeHealthyLifetime }
    : {}),
  ...(process.env.NEXT_PUBLIC_MIRROR_ID === 'yourbodywell'
    ? { [ThemeName.yourbodywell]: themeYourBodywell }
    : {}),
  ...(process.env.NEXT_PUBLIC_MIRROR_ID === 'bodycoach'
    ? { [ThemeName.bodycoach]: themeBodyCoach }
    : {}),
  ...(process.env.NEXT_PUBLIC_MIRROR_ID === 'dietplanners'
    ? { [ThemeName.dietplanners]: themeDietPlanners }
    : {}),
  ...(process.env.NEXT_PUBLIC_MIRROR_ID === 'weightcutting'
    ? { [ThemeName.weightcutting]: themeWeightCutting }
    : {}),
  ...(process.env.NEXT_PUBLIC_MIRROR_ID === 'healthyplans'
    ? { [ThemeName.healthyplans]: themeHealthyPlans }
    : {}),
  ...(process.env.NEXT_PUBLIC_MIRROR_ID === 'eathappyhealthy'
    ? { [ThemeName.eathappyhealthy]: themeEatHappyHealthy }
    : {}),
  ...(process.env.NEXT_PUBLIC_MIRROR_ID === 'getshape'
    ? { [ThemeName.getshape]: themeGetShape }
    : {}),
  ...(process.env.NEXT_PUBLIC_MIRROR_ID === 'healthycareonline'
    ? { [ThemeName.healthycareonline]: themeHealhyCareOnline }
    : {}),
  ...(process.env.NEXT_PUBLIC_MIRROR_ID === 'eatingpro'
    ? { [ThemeName.eatingpro]: themeEatingPro }
    : {}),
  ...(process.env.NEXT_PUBLIC_MIRROR_ID === 'personalizedmeals'
    ? { [ThemeName.personalizedmeals]: themePersonalizedMeals }
    : {}),
  ...(process.env.NEXT_PUBLIC_MIRROR_ID === 'amofit'
    ? { [ThemeName.amofit]: themeAmofit }
    : {}),
  ...(process.env.NEXT_PUBLIC_MIRROR_ID === 'dietcoaches'
    ? { [ThemeName.dietcoaches]: themeDietCoaches }
    : {}),
  ...(process.env.NEXT_PUBLIC_MIRROR_ID === 'fastingpro'
    ? { [ThemeName.fastingpro]: themeFastingPro }
    : {}),
  ...(process.env.NEXT_PUBLIC_MIRROR_ID === 'feelgoodinc'
    ? { [ThemeName.feelgoodinc]: themeFeelGoodInc }
    : {}),
  ...(process.env.NEXT_PUBLIC_MIRROR_ID === 'mealchallenge'
    ? { [ThemeName.mealchallenge]: themeMealChallenge }
    : {}),
  ...(process.env.NEXT_PUBLIC_MIRROR_ID === 'healthymeals'
    ? { [ThemeName.healthymeals]: themeHealthyMeals }
    : {}),
  ...(process.env.NEXT_PUBLIC_MIRROR_ID === 'bodymaker'
    ? { [ThemeName.bodymaker]: themeBodymaker }
    : {}),
  ...(process.env.NEXT_PUBLIC_MIRROR_ID === 'bestweight'
    ? { [ThemeName.bestweight]: themeBestWeight }
    : {}),
  ...(process.env.NEXT_PUBLIC_MIRROR_ID === 'betterplan'
    ? { [ThemeName.betterplan]: themeBetterPlan }
    : {}),
  ...(process.env.NEXT_PUBLIC_MIRROR_ID === 'bodyimprover'
    ? { [ThemeName.bodyimprover]: themeBodyimprover }
    : {}),
  ...(process.env.NEXT_PUBLIC_MIRROR_ID === 'improveme'
    ? { [ThemeName.improveme]: themeImproveMe }
    : {}),
  ...(process.env.NEXT_PUBLIC_MIRROR_ID === 'gethealthy'
    ? { [ThemeName.gethealthy]: themeGetHealthy }
    : {}),
  ...(process.env.NEXT_PUBLIC_MIRROR_ID === 'weightdecrease'
    ? { [ThemeName.weightdecrease]: themeWeightDecrease }
    : {}),
  ...(process.env.NEXT_PUBLIC_MIRROR_ID === 'gohungry'
    ? { [ThemeName.gohungry]: themeGoHungry }
    : {}),
  ...(process.env.NEXT_PUBLIC_MIRROR_ID === 'eatnothing'
    ? { [ThemeName.eatnothing]: themeEatNothing }
    : {}),
  ...(process.env.NEXT_PUBLIC_MIRROR_ID === 'bestsnack'
    ? { [ThemeName.bestsnack]: themeBestSnack }
    : {}),
  ...(process.env.NEXT_PUBLIC_MIRROR_ID === 'healthybites'
    ? { [ThemeName.healthybites]: themeHealthyBites }
    : {}),
  ...(process.env.NEXT_PUBLIC_MIRROR_ID === 'rightshape'
    ? { [ThemeName.rightshape]: themeRightShape }
    : {}),
  ...(process.env.NEXT_PUBLIC_MIRROR_ID === 'perfectbody'
    ? { [ThemeName.perfectbody]: themePerfectBody }
    : {}),
  ...(process.env.NEXT_PUBLIC_MIRROR_ID === 'dietmaker'
    ? { [ThemeName.dietmaker]: themeDietMaker }
    : {}),
  ...(process.env.NEXT_PUBLIC_MIRROR_ID === 'energymonster'
    ? { [ThemeName.energymonster]: themeEnergyMonster }
    : {}),
  ...(process.env.NEXT_PUBLIC_MIRROR_ID === 'gainweight'
    ? { [ThemeName.gainweight]: themeGainWeight }
    : {}),
  ...(process.env.NEXT_PUBLIC_MIRROR_ID === 'diethacker'
    ? { [ThemeName.diethacker]: themeDietHacker }
    : {}),
  ...(process.env.NEXT_PUBLIC_MIRROR_ID === 'bodyguard'
    ? { [ThemeName.bodyguard]: themeBodyGuard }
    : {}),
  ...(process.env.NEXT_PUBLIC_MIRROR_ID === 'fitkeeper'
    ? { [ThemeName.fitkeeper]: themeFitkeeper }
    : {}),
  ...(process.env.NEXT_PUBLIC_MIRROR_ID === 'harnafit'
    ? { [ThemeName.harnafit]: themeHarnafit }
    : {}),
  ...(process.env.NEXT_PUBLIC_MIRROR_ID === 'harnatech'
    ? { [ThemeName.harnatech]: themeHarnatech }
    : {}),
};

export { ThemeName, themes };
