import styled, { css } from 'styled-components';

import { themeColor, smMedia } from 'src/theme/utils';
import { Overline } from 'src/components-2.0/Typography';

export const NavWrapper = styled.nav`
  display: flex;
  margin-left: 16px;
`;

interface NavSideContainerProps {
  isNavVisible?: boolean;
}

export const NavSideBackground = styled.div.attrs(
  (
    props: NavSideContainerProps
  ): Partial<React.HtmlHTMLAttributes<HTMLDivElement>> => ({
    style: {
      visibility: props.isNavVisible ? 'visible' : 'hidden',
    },
  })
)<NavSideContainerProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background: ${themeColor('black80')};
  opacity: 0.9;
  z-index: 900;
`;

export const NavSideContainer = styled.div.attrs(
  (
    props: NavSideContainerProps
  ): Partial<React.HtmlHTMLAttributes<HTMLDivElement>> => ({
    style: {
      right: props.isNavVisible ? '0px' : '-400px',
    },
  })
)<NavSideContainerProps>`
  background: ${themeColor('black0')};
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-width: calc(100% - 20px);
  overflow: auto;
  padding: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: right 0.3s;
  width: 400px;
  z-index: 900;
`;

export const NavSideClose = styled.div`
  display: flex;
  height: 24px;
  margin: 24px 40px 24px auto;
`;

export const NavSideLinks = styled.ul`
  display: flex;
  flex-direction: column;
`;

export const NavSideLinkItem = styled.li<{ isActive: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  background: ${(props): any =>
    props.isActive ? themeColor('primary0') : 'transparent'};
  a,
  span {
    font-size: 22px;
    line-height: 28px;
    font-weight: bold;
    outline: none;
    text-decoration: none;
    padding: 12px 24px 13px;
    width: 100%;
    color: ${(props): any =>
      props.isActive ? themeColor('primary50') : themeColor('black80')};
  }
  a:hover,
  span:hover {
    color: ${themeColor('primary40')};
  }
  a:active,
  span:active {
    color: ${themeColor('primary60')};
  }
  &.lang {
    ${smMedia(
      css`
        display: none;
      `
    )}
  }
`;

export const NavSideLinkItemInner = styled.li`
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  position: relative;
  margin: 0 24px 0 56px;
  border-bottom: 1px solid ${themeColor('black10')};
  a,
  span {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: ${themeColor('black80')};
    outline: none;
    text-decoration: none;
    width: 100%;
    padding: 16px 0;
    cursor: pointer;
  }
  svg {
    width: 18px;
    height: 13px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
  }
  svg use {
    stroke: ${themeColor('primary50')};
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
  svg path {
    stroke: inherit;
    fill: inherit;
  }
  a:hover,
  span:hover {
    color: ${themeColor('primary40')};
  }
  a:active,
  span:active {
    color: ${themeColor('primary60')};
  }
`;

export const NavSideContactUs = styled.div`
  display: flex;
  overflow: visible;
  white-space: nowrap;
  background: ${themeColor('blue0')};
  border-radius: 8px;
  padding: 16px;
  margin: 32px 24px 64px 24px;
  a {
    font-size: 18px;
    line-height: 26px;
    font-weight: 500;
    color: ${themeColor('primary50')};
    outline: none;
    text-decoration: none;
  }
`;

export const ChevronIcon = styled.div<{ isOpen: boolean }>`
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  width: 54px;
  height: 54px;
  background-image: url(/icons/chevron_up.svg);
  background-repeat: no-repeat;
  background-position: center;
  transform: ${({ isOpen }): any => (isOpen ? 'none' : 'rotate(180deg)')};
  transition: all 300ms ease-in-out;
`;

export const MailIcon = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  background-image: url(/icons/mail.svg);
  background-repeat: no-repeat;
  background-position: center;
`;

export const NavSideContactUsPhone = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin: 24px 24px 0;
`;

export const NavSideContactUsPhoneLink = styled.a`
  align-items: center;
  background: ${themeColor('primary0')};
  border-radius: 8px;
  color: ${themeColor('primary50')};
  display: flex;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.12px;
  line-height: 24px;
  padding: 16px;
  text-decoration: none;
  width: 100%;
  margin-bottom: 8px;

  span {
    margin-left: 4px;
  }
`;

export const NavSideContactUsPhoneCaption = styled(Overline)`
  color: ${themeColor('black50')};
  padding: 0 16px;
  text-align: left;
  width: 100%;

  &:first-of-type {
    margin-bottom: 8px;
  }
`;

export const NavSideContactUsEmail = styled.div`
  align-items: center;
  display: flex;
  margin: 24px 24px 32px;
`;

export const NavSideContactUsEmailLink = styled.a`
  align-items: center;
  border-radius: 8px;
  color: ${themeColor('primary50')};
  display: flex;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.12px;
  line-height: 24px;
  padding: 16px;
  text-decoration: none;
  width: 100%;
  margin-bottom: 8px;

  span {
    margin-left: 4px;
  }

  svg {
    background-color: ${themeColor('primary50')};
    border-radius: 50%;
  }
`;
