/* istanbul ignore file */

export const amplitudeInit = async (): Promise<void> => {
  // try {
  //   const newAmplitude = await import('amplitude-js');
  //   window.amplitude = newAmplitude;
  // } catch (error) {
  //   console.error('amplitude load error');
  // }
};

export const amplitudeLogEvent = (event: string, data?: any): void => {
  // if (false) {
  //    window.amplitude
  //      ?.getInstance()
  //      ?.logEvent(event || 'UNDEFINED_EVENT_NAME', data);
  // }
};

export const amplitudeSetUserProperties = (properties: any): void => {
  // window.amplitude?.getInstance()?.setUserProperties(properties);
};

export const amplitudeRevenue = ({
  productId,
  price,
  quantity,
}: {
  productId: string;
  price: number;
  quantity: number;
}): void => {
  // const revenue = new window.amplitude.Revenue()
  //   .setProductId(productId)
  //   .setPrice(price)
  //   .setQuantity(quantity);
  // window.amplitude.getInstance().logRevenueV2(revenue);
};
