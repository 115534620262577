import Cookie from 'js-cookie';

export const setCookie = (
  name: string,
  value: any,
  options?: Cookies.CookieAttributes
): string =>
  Cookie.set(name, value, {
    path: '/',
    expires: 1,
    ...(options || {}),
  }) || '';

export const getCookie = (name: string): any => Cookie.get(name) || null;

export const removeCookie = (name: string): void => {
  Cookie.remove(name);
};

export const getCookieFromString = (
  name: string,
  cookieStr: string | undefined
): string | null => {
  if (!name) {
    return null;
  }

  const matches = (cookieStr || '').match(
    new RegExp(
      `(?:^|; )${name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1')}=([^;]*)`
    )
  );

  return matches && matches[1] ? decodeURIComponent(matches[1]) : null;
};
