import { AppProps } from 'next/app';
import { memo, useState, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { useAmp } from 'next/amp';
import { ThemeProvider } from 'styled-components';

import 'intl-pluralrules';
import 'focus-visible';
import 'intersection-observer';

import { themes } from 'src/theme/constants';

import { amplitudeInit, amplitudeSetUserProperties } from 'src/utils/amplitude';
import { sendGTMEvent, getPurePrice } from 'src/utils/gtm';
import { getUrlParamValue } from 'src/utils/url';
import { getUserProperties, setUserProperties } from 'src/utils/user';
import {
  getActiveABTestOption,
  UserAbTestsState,
  getActiveAbTestsPaths,
} from 'src/utils/abTests';
import { getCookie, setCookie } from 'src/utils/cookie';

import { getUserCountryInfo } from 'src/api/user';

import {
  getLocalStorageItem,
  setLocalStorageItem,
} from 'src/utils/localStorage';

import SendPulseWebPush from './components/SendPulseWebPush';
import Header from './components/Header/Header';
import Analytics from './components/Analytics';
import GlobalStyleNormalizeCSS from './components/GlobalStyle';
import OpenGraph from './components/OpenGraph';
import Favicons from './components/Favicons';

import { AppContainer } from './styled';

const ENABLED_AB_TESTS_PATHS = getActiveAbTestsPaths();

const theme = themes[process.env.NEXT_PUBLIC_MIRROR_ID || 'default'];

function App({
  Component,
  pageProps,
  err,
}: AppProps & {
  err: any;
}): JSX.Element {
  const isAmp = useAmp();
  const router = useRouter();

  const [userCountryInfo, setUserCountryInfo] = useState<{
    countryCode: string;
    showModalWindow: boolean;
  }>({ countryCode: '', showModalWindow: false });

  const isAppMountedRef = useRef(false);

  const isCurrentPathInAbTest = ENABLED_AB_TESTS_PATHS.includes(
    router.asPath.split('?')[0]
  );

  const abTestRedirectUrlRef = useRef('');
  const isAppContentHiddenByAbTestRef = useRef(false);

  if (isCurrentPathInAbTest) {
    isAppContentHiddenByAbTestRef.current = true;
  }

  const initABTests = (): void => {
    const userAbTestsState = (getLocalStorageItem('abTestsState') ||
      {}) as UserAbTestsState;
    const { testName, option } =
      getActiveABTestOption(router, userAbTestsState) || {};

    if (
      getCookie('st') !== '0' &&
      option &&
      testName &&
      router.asPath !== option.url
    ) {
      setLocalStorageItem('abTestsState', {
        ...userAbTestsState,
        [testName]: option.url,
      });

      abTestRedirectUrlRef.current = option.url;
      router.push(option.url);
    } else {
      abTestRedirectUrlRef.current = router.asPath;
    }
  };

  const init = (): void => {
    setUserProperties(router);

    const fbClidUrl = getUrlParamValue('fbclid', router.asPath);

    if (fbClidUrl) {
      setCookie('amplitude_fbclid', fbClidUrl, {
        expires: 90,
      });

      setCookie('custom_fbc', `fb.1.${Date.now()}.${fbClidUrl}`, {
        expires: 90,
      });
    }

    setUserProperties(router);
  };

  function showAppContent(): void {
    (document.querySelector('.app-container') as any).style.opacity = 1;
  }

  useEffect(() => {
    if (
      isAppContentHiddenByAbTestRef.current &&
      abTestRedirectUrlRef.current === router.asPath
    ) {
      showAppContent();
    }
  }, [router.asPath]);

  useEffect(() => {
    if (pageProps?.token) {
      setCookie('token', pageProps.token, { expires: 30 });
    }
    if (pageProps?.userData?.uuid) {
      setCookie('userId', pageProps?.userData?.uuid, { expires: 30 });
    }
  }, [pageProps?.token, pageProps?.uuid]);

  useEffect(() => {
    isAppMountedRef.current = true;

    async function initUserCountryInfo(): Promise<void> {
      const response = await getUserCountryInfo();
      setUserCountryInfo(response);
    }

    function handleBeforeRouteChange(url: string): void {
      const nextExpName = getUrlParamValue('expName', url);
      const nextExpGrp = getUrlParamValue('expGrp', url);

      if (nextExpName) {
        setCookie('expName', nextExpName, { expires: 365 });
      }

      if (nextExpGrp) {
        setCookie('expGrp', nextExpGrp, { expires: 365 });
      }
    }

    const userProperties = {
      ...getUserProperties(),
      quiz_language: router.locale,
    };

    sendGTMEvent({ event: 'setUserProperties', ...userProperties });

    amplitudeSetUserProperties(userProperties);

    setTimeout(() => {
      // amplitudeInit();

      try {
        import('ua-parser-js').then((module) => {
          const UAParser = module.default;
          const UAParserInstance = new UAParser(window.navigator.userAgent);
          const userOS = UAParserInstance.getOS();

          amplitudeSetUserProperties({
            Platform_Type: userOS.name,
            Platform_Version: userOS.version,
          });
        });
      } catch (error) {
        console.error('ua-parser-js load failed');
      }
    }, 4000);

    initUserCountryInfo();

    router.events.on('beforeHistoryChange', handleBeforeRouteChange);

    return (): void => {
      router.events.off('beforeHistoryChange', handleBeforeRouteChange);
    };
  }, []);

  if (typeof window !== 'undefined') {
    if (pageProps?.userData?.email) {
      (window as any).userEmail = pageProps?.userData?.email;
    }

    (window as any).ltv = Math.round(
      pageProps?.ltv ||
        (getCookie('ampPrice') ? getPurePrice(getCookie('ampPrice')) : 0)
    );

    if (!isAppMountedRef.current) {
      init();
    }

    if (isCurrentPathInAbTest) {
      initABTests();
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <OpenGraph
        {...pageProps.meta}
        img={
          process.env.NEXT_PUBLIC_MIRROR_ID === 'harnafit' ||
          process.env.NEXT_PUBLIC_MIRROR_ID === 'harnatech'
            ? `${process.env.NEXT_PUBLIC_ORIGIN}/harna-og-img.png`
            : router.pathname !== '/blog/[slug]' &&
              router.pathname !== '/achievement/[achievementId]' &&
              router.pathname !== '/blockquote/[blockquoteId]' &&
              router.pathname !== '/course/[courseId]' &&
              router.pathname !== '/challenge/[challengeId]'
            ? `${process.env.NEXT_PUBLIC_ORIGIN}/UM_Sharing_Image.png`
            : pageProps.img
        }
        url={`${process.env.NEXT_PUBLIC_ORIGIN}${router.asPath}`}
        noIndex
        isWithChat={!!pageProps?.isWithChat}
      />

      <Favicons key="favicons" />

      <Analytics key="analytics" />

      {!isAmp && <GlobalStyleNormalizeCSS key="globalStyle" />}

      <SendPulseWebPush />

      {!pageProps.isWithoutHeader && (
        <Header
          userData={pageProps.userData}
          hideLocaleSwitcher
          pathsForSlug={pageProps.pathsForSlug || null}
          // blogCategories={pageProps.categories}
          isContactUs={pageProps.isContactUs}
          isReturnPanelShown={pageProps.isReturnPanelShown}
          countryCode={userCountryInfo.countryCode}
        />
      )}

      <AppContainer
        $size={pageProps.appContainerSize}
        className="app-container"
        style={isCurrentPathInAbTest ? { opacity: 0 } : {}}
      >
        <Component
          {...pageProps}
          {...(pageProps.passUserCountryInfo ? { userCountryInfo } : {})}
          err={err}
        />
      </AppContainer>
    </ThemeProvider>
  );
}

export default memo(App);
