// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

if (process.env.NODE_ENV === 'production' && !!process.env.SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps,
    environment: process.env.SENTRY_ENVIRONMENT,
    ignoreErrors: [
      "null is not an object (evaluating 'e.newScriptElement.parentNode.removeChild')",
      "Cannot read properties of null (reading 'removeChild')",
      "Cannot read property 'removeChild' of null",
      'getPercent is not defined',
      "InvalidStateError: Failed to execute 'transaction' on 'IDBDatabase': The database connection is closing.",
      /^_error.js getInitialProps missing data at path/,
      "Failed to execute 'getBattery' on 'Navigator': Illegal invocation",
    ],
  });
}
