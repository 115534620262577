import { memo, MouseEvent } from 'react';

import { IconSizes, IconTypes } from 'src/components/Icon/constants';

import { IconClickableStyled, IconStyled } from './icon-styled';

export interface IconProps {
  type?: IconTypes;
  url?: string;
  size?: IconSizes;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  disabled?: boolean;
  name?: string;
}

// eslint-disable-next-line react/display-name
export const Icon = memo(
  ({
    type,
    url,
    size = IconSizes.m,
    onClick,
    className,
    disabled,
    name,
    ...props
  }: IconProps): JSX.Element =>
    onClick ? (
      <IconClickableStyled
        iconType={type}
        iconUrl={url}
        $size={size}
        onClick={onClick}
        className={className}
        disabled={disabled}
        name={name}
        {...props}
      />
    ) : (
      <IconStyled
        iconType={type}
        iconUrl={url}
        $size={size}
        className={className}
        {...props}
      />
    )
);
