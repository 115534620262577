import { RoutePath } from 'src/constants/route-path';

export const MENU_LINKS = [
  { id: 'blog', href: RoutePath.blog },
  { id: 'authors', href: `${RoutePath.blog}${RoutePath.authors}` },
  { id: 'faq', href: RoutePath.FAQ },
  { id: 'about', href: RoutePath.about },
  { id: 'contact', href: RoutePath.contact },
];

export const ACCOUNT_LINKS = [
  { id: 'mealplan', href: RoutePath.mealPlan },
  { id: 'mySubscription', href: RoutePath.mySubscription },
];

export const TERMS_LINKS = [
  { id: 'refund', href: RoutePath.refundPolicy },
  { id: 'privacy', href: RoutePath.privacyPolicy },
  { id: 'terms', href: RoutePath.termsOfService },
  { id: 'subscription', href: RoutePath.subscriptionTerms },
  { id: 'cookiePolicy', href: RoutePath.cookiePolicy },
  {
    id: 'unimealEditorialPolicy',
    href: RoutePath.unimealEditorialPolicy,
  },
  { id: 'dmca', href: RoutePath.dmca },
  {
    id: 'doNotSellMyPersonalInformation',
    href: RoutePath.doNotSellMyPersonalInformation,
  },
];
