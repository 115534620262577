import { memo, useEffect, useRef, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';

import Image from 'next/image';

import { amplitudeLogEvent } from 'src/utils/amplitude';

import { sendGTMEvent } from 'src/utils/gtm';

import {
  AppLinksWrapper,
  AppIcon,
  AppLinksText,
  MobileAppLink,
  DesktopAppLinksWrapper,
  DesktopAppLink,
} from './app-links-styled';

const BADGES_LIST = [
  {
    id: 'appstore',
    type: 'AppStore',
    url: 'https://apps.apple.com/us/app/unimeal-weight-loss-plan/id1501323016?ls=1',
  },
  {
    id: 'googleplay',
    type: 'Google',
    url: 'https://play.google.com/store/apps/details?id=com.unimeal.android',
  },
  {
    id: 'appgallery',
    type: 'Gallery',
    url: 'https://appgallery7.huawei.com/#/app/C102561767',
  },
];

function AppLinks(): JSX.Element {
  const { t } = useTranslation('meal-plan');

  const [isMobile, setIsMobile] = useState(false);
  const [isIconVisible, setIsIconVisible] = useState(false);

  const resizeTimeoutRef = useRef<any>(null);
  const iconRef = useRef<HTMLDivElement>(null);

  const handleResize = (): void => {
    clearTimeout(resizeTimeoutRef.current);

    resizeTimeoutRef.current = setTimeout(() => {
      setIsMobile(window.innerWidth < 767);
      setIsIconVisible(window.innerWidth > 1279);
    }, 400);
  };

  const handleScroll = (): void => {
    if (iconRef.current !== null) {
      iconRef.current.style.transform = `scale(${
        window.scrollY < 72 ? 1 - window.scrollY * 0.004 : 0.68
      })`;
    }
  };

  useEffect(() => {
    setIsMobile(window.innerWidth < 767);
    setIsIconVisible(window.innerWidth > 1365);

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);

    return (): void => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <AppLinksWrapper>
      <AppLinksText>{t('witchToTheUnimealApp')}</AppLinksText>
      {isMobile ? (
        <MobileAppLink
          href="http://onelink.to/5ynpzp"
          target="_blank"
          rel="noopener noreferrer"
          onClick={(): void => {
            amplitudeLogEvent('TYP_download_click');
            sendGTMEvent({ event: 'TYP_download_click' });
          }}
        >
          {t('downloadApp')}
        </MobileAppLink>
      ) : (
        <>
          {isIconVisible && (
            <AppIcon ref={iconRef}>
              <Image
                src={`${process.env.ASSETS_PUBLIC_PATH}images/meal-plan/app.svg`}
                alt="App Icon"
                height="98"
                width="98"
              />
            </AppIcon>
          )}
          <DesktopAppLinksWrapper>
            {BADGES_LIST.map((badge: any) => (
              <DesktopAppLink
                key={badge.id}
                href={badge.url}
                target="_blank"
                rel="noopener noreferrer"
                onClick={(): void => {
                  amplitudeLogEvent(`TYP_${badge.type}_click`);
                  sendGTMEvent({ event: `TYP_${badge.type}_click` });
                }}
              >
                <Image
                  src={`${process.env.ASSETS_PUBLIC_PATH}images/badges/${badge.id}.png`}
                  alt={badge.type}
                  height="64"
                  width="217"
                />
              </DesktopAppLink>
            ))}
          </DesktopAppLinksWrapper>
        </>
      )}
    </AppLinksWrapper>
  );
}

export default memo(AppLinks);
