export const sendGTMEvent = (
  params: { event: string } & { [key: string]: any }
): void => {
  (window as any).dataLayer = (window as any).dataLayer || [];

  (window as any).dataLayer.push(params);
};

export const sendObEvent = (eventId: string): void => {
  const { obApi } = window as any;

  if (obApi) {
    obApi('track', eventId);
  }
};

export const getPurePrice = (price: number): number =>
  0.98 * (price * 0.985 - (price * 0.035 + 0.39));

export const sendFBQEvent = (
  type: string,
  name: string,
  price: number,
  eventID?: string
): void => {
  const { fbq } = window as any;

  if (fbq) {
    const params = [
      type,
      name,
      {
        value: price,
        currency: 'USD',
      },
      ...(eventID ? [{ eventID }] : []),
    ];

    fbq(...params);
  }
};

// export const sendPintrkEvent = (
//   type: string,
//   name: string,
//   price: number
// ): void => {
//   const { pintrk } = window as any;

//   if (pintrk) {
//     pintrk(type, name, {
//       value: price,
//       currency: 'USD'
//     });
//   }
// };
