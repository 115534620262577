import { memo } from 'react';

import * as icons from './icons';

import IconWrapper from './mono-icon-styled';

export type MonoIconProps = React.HTMLAttributes<HTMLOrSVGElement> & {
  name: keyof typeof icons;
  sizeRate?: number;
};

function MonoIcon({
  name,
  sizeRate,
  ...props
}: MonoIconProps): JSX.Element | null {
  const icon = icons[name];

  if (!icon) {
    return null;
  }

  return (
    <IconWrapper viewBox={icon.viewBox} $sizeRate={sizeRate} {...props}>
      <use
        xlinkHref={`${process.env.SVG_SPRITE_FILENAME}#${icon.id}`}
        xmlns="http://www.w3.org/2000/svg"
      />
    </IconWrapper>
  );
}

export default memo(MonoIcon);
