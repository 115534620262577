import { memo, useEffect, useRef, useState } from 'react';
import Script from 'next/script';
import { useRouter } from 'next/router';

import { sendGTMEvent } from 'src/utils/gtm';

const gtagId = process.env.NEXT_PUBLIC_GOOGLE_TAG_ID;
const fbPixelIds = (process.env.NEXT_PUBLIC_FB_PIXEL_IDS || '').split(',');
const quantcastId = process.env.NEXT_PUBLIC_QUANTCAST_ID;

function Analytics(): JSX.Element {
  const router = useRouter();
  const passedScrollDepthTresholds = useRef<number[]>([]);

  const isBlogPath = router.pathname.startsWith('/blog');
  const scriptsRenderTimeoutRef = useRef<any>(null);
  const [isScriptsRender, toggleIsScriptsRender] = useState(!isBlogPath);

  useEffect(() => {
    if (isBlogPath && !isScriptsRender && !scriptsRenderTimeoutRef.current) {
      scriptsRenderTimeoutRef.current = setTimeout(() => {
        toggleIsScriptsRender(true);
      }, 5000);
    } else if (!isBlogPath && !isScriptsRender) {
      clearTimeout(scriptsRenderTimeoutRef.current);
      toggleIsScriptsRender(true);
    }
  }, [isBlogPath, isScriptsRender]);

  useEffect(() => {
    const scrollDepthTresholds = [100, 75, 50, 25];
    const articleName = router.query.slug;

    function handleWindowScroll(): void {
      const htmlElement = document.documentElement;
      const bodyElement = document.body;

      const scrollDepthPercent =
        ((htmlElement.scrollTop || bodyElement.scrollTop) /
          ((htmlElement.scrollHeight || bodyElement.scrollHeight) -
            htmlElement.clientHeight)) *
        100;

      const scrollDepthPercentTreshold = scrollDepthTresholds.find(
        (item) => item <= scrollDepthPercent
      );

      if (
        scrollDepthPercentTreshold &&
        !passedScrollDepthTresholds.current.includes(scrollDepthPercentTreshold)
      ) {
        passedScrollDepthTresholds.current.push(scrollDepthPercentTreshold);
        sendGTMEvent({
          event: 'scrollDepthTrack',
          scrollDepthPercent: scrollDepthPercentTreshold,
          articleName,
        });
      }
    }

    if (articleName) {
      window.addEventListener('scroll', handleWindowScroll);
    }

    return (): void => {
      if (articleName) {
        window.removeEventListener('scroll', handleWindowScroll);
      }

      passedScrollDepthTresholds.current = [];
    };
  }, [router.query.slug]);

  return (
    <>
      <Script
        id="amplitudeScript"
        dangerouslySetInnerHTML={{
          __html: `(function(e,t){var n=e.amplitude||{_q:[],_iq:{}};function s(e,t){e.prototype[t]=function(){this._q.push([t].concat(Array.prototype.slice.call(arguments,0)));return this}}var o=function(){this._q=[];return this};var a=["add","append","clearAll","prepend","set","setOnce","unset"];for(var c=0;c<a.length;c++){s(o,a[c])}n.Identify=o;var u=function(){this._q=[];return this};var l=["setProductId","setQuantity","setPrice","setRevenueType","setEventProperties"];for(var p=0;p<l.length;p++){s(u,l[p])}n.Revenue=u;var d=["init","logEvent","logRevenue","setUserId","setUserProperties","setOptOut","setVersionName","setDomain","setDeviceId","enableTracking","setGlobalUserProperties","identify","clearUserProperties","setGroup","logRevenueV2","regenerateDeviceId","groupIdentify","onInit","logEventWithTimestamp","logEventWithGroups","setSessionId","resetSessionId"];function v(e){function t(t){e[t]=function(){e._q.push([t].concat(Array.prototype.slice.call(arguments,0)))}}for(var n=0;n<d.length;n++){t(d[n])}}v(n);n.getInstance=function(e){e=(!e||e.length===0?"$default_instance":e).toLowerCase();if(!n._iq.hasOwnProperty(e)){n._iq[e]={_q:[]};v(n._iq[e])}return n._iq[e]};e.amplitude=n})(window,document);window.amplitude.getInstance().init('${process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY}');`,
        }}
      />

      {isScriptsRender && (
        <>
          {fbPixelIds.length > 0 && (
            <Script
              id="fbPixelScript"
              dangerouslySetInnerHTML={{
                __html: `!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,document,'script','https://connect.facebook.net/en_US/fbevents.js');${fbPixelIds
                  .map((id) => `fbq('init', '${id}')`)
                  .join(';')};fbq('track', 'PageView');`,
              }}
            />
          )}

          {!!gtagId && (
            <Script
              id="gtagScript"
              dangerouslySetInnerHTML={{
                __html: `(function (w, d, s, l, i) {
  w[l] = w[l] || []; w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
  var f = d.getElementsByTagName(s)[0],
    j = d.createElement(s),
    dl = l != "dataLayer" ? "&l=" + l : "";
  j.async = true;
  j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
  f.parentNode.insertBefore(j, f);
})(window,document,'script','dataLayer','${gtagId}');`,
              }}
            />
          )}
        </>
      )}

      {quantcastId && (
        <Script
          id="quancastScript"
          dangerouslySetInnerHTML={{
            __html: `function initQuanCast() {
  var host = window.location.hostname;
  var element = document.createElement('script');
  var firstScript = document.getElementsByTagName('script')[0];
  var url = 'https://quantcast.mgr.consensu.org'
    .concat('/choice/', '${quantcastId}', '/', host, '/choice.js')
  var uspTries = 0;
  var uspTriesLimit = 3;

  element.async = true;
  element.type = 'text/javascript';
  element.src = url;

  firstScript.parentNode.insertBefore(element, firstScript);

  function makeStub() {
    var TCF_LOCATOR_NAME = '__tcfapiLocator';
    var queue = [];
    var win = window;
    var cmpFrame;

    function addFrame() {
      var doc = win.document;
      var otherCMP = !!(win.frames[TCF_LOCATOR_NAME]);

      if (!otherCMP) {
        if (doc.body) {
          var iframe = doc.createElement('iframe');

          iframe.style.cssText = 'display:none';
          iframe.name = TCF_LOCATOR_NAME;
          doc.body.appendChild(iframe);
        } else {
          setTimeout(addFrame, 5);
        }
      }
      return !otherCMP;
    }

    function tcfAPIHandler() {
      var gdprApplies;
      var args = arguments;

      if (!args.length) {
        return queue;
      } else if (args[0] === 'setGdprApplies') {
        if (
          args.length > 3 &&
          args[2] === 2 &&
          typeof args[3] === 'boolean'
        ) {
          gdprApplies = args[3];
          if (typeof args[2] === 'function') {
            args[2]('set', true);
          }
        }
      } else if (args[0] === 'ping') {
        var retr = {
          gdprApplies: gdprApplies,
          cmpLoaded: false,
          cmpStatus: 'stub'
        };

        if (typeof args[2] === 'function') {
          args[2](retr);
        }
      } else {
        queue.push(args);
      }
    }

    function postMessageEventHandler(event) {
      var msgIsString = typeof event.data === 'string';
      var json = {};

      try {
        if (msgIsString) {
          json = JSON.parse(event.data);
        } else {
          json = event.data;
        }
      } catch (ignore) {}

      var payload = json.__tcfapiCall;

      if (payload) {
        window.__tcfapi(
          payload.command,
          payload.version,
          function(retValue, success) {
            var returnMsg = {
              __tcfapiReturn: {
                returnValue: retValue,
                success: success,
                callId: payload.callId
              }
            };
            if (msgIsString) {
              returnMsg = JSON.stringify(returnMsg);
            }
            event.source.postMessage(returnMsg, '*');
          },
          payload.parameter
        );
      }
    }

    while (win) {
      try {
        if (win.frames[TCF_LOCATOR_NAME]) {
          cmpFrame = win;
          break;
        }
      } catch (ignore) {}

      if (win === window.top) {
        break;
      }
      win = win.parent;
    }
    if (!cmpFrame) {
      addFrame();
      win.__tcfapi = tcfAPIHandler;
      win.addEventListener('message', postMessageEventHandler, false);
    }
  };

  makeStub();

  var uspStubFunction = function() {
    var arg = arguments;
    if (typeof window.__uspapi !== uspStubFunction) {
      setTimeout(function() {
        if (typeof window.__uspapi !== 'undefined') {
          window.__uspapi.apply(window.__uspapi, arg);
        }
      }, 500);
    }
  };

  var checkIfUspIsReady = function() {
    uspTries++;
    if (window.__uspapi === uspStubFunction && uspTries < uspTriesLimit) {
      console.warn('USP is not accessible');
    } else {
      clearInterval(uspInterval);
    }
  };

  if (typeof window.__uspapi === 'undefined') {
    window.__uspapi = uspStubFunction;
    var uspInterval = setInterval(checkIfUspIsReady, 6000);
  }
}; setTimeout(initQuanCast, 3000);`,
          }}
        />
      )}
    </>
  );
}

export default memo(Analytics);
