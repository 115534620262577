import styled from 'styled-components';

export const Hero = styled.h1`
  font-weight: 700;
  font-size: 44px;
  line-height: 52px;
  letter-spacing: -0.3px;
`;

export const H1 = styled.h1`
  font-weight: 700;
  font-size: 34px;
  line-height: 44px;
  letter-spacing: -0.2px;
`;

export const H2 = styled.h2`
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  letter-spacing: -0.15px;
`;

export const H3 = styled.h3`
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: -0.1px;
`;

export const H4 = styled.h4`
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.1px;
`;

export const Body1 = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
`;

export const Body1Semi = styled(Body1)`
  font-weight: 600;
`;

export const Body2 = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;

export const Body2Semi = styled(Body2)`
  font-weight: 600;
`;

export const SmallCaption = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
`;

export const ButtonBig = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.14px;
`;

export const ButtonSmall = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.12px;
`;

export const LinkRegular = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
`;

export const LinkSemi = styled(LinkRegular)`
  font-weight: 600;
`;

export const LinkSmall = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.12px;
`;

export const Overline = styled.p`
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

export const LearnBodyRegular = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
`;

export const LearnBodyBold = styled(LearnBodyRegular)`
  font-weight: 700;
`;
