import { NextRouter } from 'next/router';

import { getCookie, setCookie, removeCookie } from './cookie';
import { getUrlParamValue } from './url';

const utmParamsNames = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_referrer',
];

const userPropertiesNames = [
  ...utmParamsNames,
  'bid',
  'npmt',
  'aplp',
  'gglp',
  'ftm',
  'ptm',
  'pp',
  'pm',
  'st',
  'tmr',
  'pricePackageId',
  'target',
  'plans_group',
  'expName',
  'expGrp',
  'vmcid',
];

export const getUTMParams = (): { [key: string]: string } =>
  utmParamsNames.reduce(
    (result, paramName) => ({
      ...result,
      [paramName]: getCookie(paramName),
    }),
    {}
  );

export const clearUserProperties = (): void => {
  removeCookie('timer');

  userPropertiesNames.forEach((propertyName) => {
    removeCookie(propertyName);
  });
};

export const setUserProperties = (router: NextRouter): void => {
  const utmSourceInUrl = getUrlParamValue('utm_source', router.asPath);
  const isNewUtmSourceInUrl =
    utmSourceInUrl && utmSourceInUrl !== getCookie('utm_source');
  const utmCampaignInUrl = getUrlParamValue('utm_campaign', router.asPath);
  const isNewUtmCampaignInUrl =
    utmCampaignInUrl && utmCampaignInUrl !== getCookie('utm_campaign');
  const ppInUrl = getUrlParamValue('pp', router.asPath);
  const isNewPPInUrl =
    ppInUrl &&
    ppInUrl !== getCookie('pp') &&
    ppInUrl !== getCookie('pricePackageId');
  const pricePackageIdInUrl = getUrlParamValue('pricePackageId', router.asPath);
  const isNewPricePackageIdInUrl =
    pricePackageIdInUrl &&
    pricePackageIdInUrl !== getCookie('pp') &&
    pricePackageIdInUrl !== getCookie('pricePackageId');
  const ptmInUrl = getUrlParamValue('ptm', router.asPath);
  const isNewPtmInUrl = ptmInUrl && ptmInUrl !== getCookie('ptm');
  const ftmInUrl = getUrlParamValue('ftm', router.asPath);
  const isNewFtmInUrl = ftmInUrl && ftmInUrl !== getCookie('ftm');

  if (
    isNewUtmSourceInUrl ||
    isNewUtmCampaignInUrl ||
    isNewPPInUrl ||
    isNewPricePackageIdInUrl ||
    isNewPtmInUrl ||
    isNewFtmInUrl
  ) {
    clearUserProperties();
  }

  userPropertiesNames.forEach((propertyName) => {
    const propertyValueInUrl = (
      router.query[propertyName] ||
      getUrlParamValue(propertyName, router.asPath)
    ).toString();

    const propertyValueInCookie = getCookie(propertyName);
    const propertyValue = propertyValueInUrl || propertyValueInCookie || '';

    setCookie(propertyName, propertyValue, { expires: 365 });

    if (!!propertyValueInUrl && propertyName === 'pm') {
      setCookie(
        'pm',
        propertyValueInUrl
          .split('-')
          .every(
            (value) =>
              value === '1' || value === '2' || value === '3' || value === '4'
          )
          ? propertyValue
          : '',
        { expires: 365 }
      );
    }

    if (!!propertyValueInUrl && propertyName === 'pp') {
      setCookie('pricePackageId', propertyValueInUrl, { expires: 365 });
    }

    if (!!propertyValueInUrl && propertyName === 'pricePackageId') {
      setCookie('pp', propertyValueInUrl, { expires: 365 });
    }
  });
};

export const getUserProperties = (): { [key: string]: string } =>
  userPropertiesNames.reduce((result, propertyName) => {
    const propertyValue = getCookie(propertyName);

    if (propertyValue) {
      return {
        ...result,
        [propertyName]: propertyValue,
      };
    }

    return result;
  }, {});
