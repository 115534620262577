import Head from 'next/head';
import { memo } from 'react';

function Favicons(): JSX.Element {
  return (
    <Head>
      <link
        rel="shortcut icon"
        href={`/${process.env.NEXT_PUBLIC_MIRROR_ID}.ico`}
      />
    </Head>
  );
}

export default memo(Favicons);
