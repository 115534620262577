export enum ThemeName {
  default = 'default',
  energybooster = 'energybooster',
  changeyourself = 'changeyourself',
  fastingplan = 'fastingplan',
  bodymanagement = 'bodymanagement',
  healthydietfitness = 'healthydietfitness',
  loseyourweight = 'loseyourweight',
  bodytransformation = 'bodytransformation',
  mealsplan = 'mealsplan',
  bodywellness = 'bodywellness',
  healthwellness = 'healthwellness',
  fastingtrainer = 'fastingtrainer',
  healthylifetime = 'healthylifetime',
  yourbodywell = 'yourbodywell',
  bodycoach = 'bodycoach',
  dietplanners = 'dietplanners',
  weightcutting = 'weightcutting',
  healthyplans = 'healthyplans',
  eathappyhealthy = 'eathappyhealthy',
  getshape = 'getshape',
  healthycareonline = 'healthycareonline',
  eatingpro = 'eatingpro',
  personalizedmeals = 'personalizedmeals',
  amofit = 'amofit',
  dietcoaches = 'dietcoaches',
  fastingpro = 'fastingpro',
  feelgoodinc = 'feelgoodinc',
  mealchallenge = 'mealchallenge',
  healthymeals = 'healthymeals',
  bodymaker = 'bodymaker',
  bestweight = 'bestweight',
  betterplan = 'betterplan',
  bodyimprover = 'bodyimprover',
  improveme = 'improveme',
  gethealthy = 'gethealthy',
  weightdecrease = 'weightdecrease',
  gohungry = 'gohungry',
  eatnothing = 'eatnothing',
  bestsnack = 'bestsnack',
  healthybites = 'healthybites',
  rightshape = 'rightshape',
  perfectbody = 'perfectbody',
  dietmaker = 'dietmaker',
  energymonster = 'energymonster',
  gainweight = 'gainweight',
  diethacker = 'diethacker',
  bodyguard = 'bodyguard',
  fitkeeper = 'fitkeeper',
  harnafit = 'harnafit',
  harnatech = 'harnatech',
}

export default ThemeName;
