import styled, { css, CSSProp } from 'styled-components';

import { themeColor, themeImages, smMedia, mdMedia } from 'src/theme/utils';

type HeaderProps = {
  $isSticky?: boolean;
  $isDropped?: boolean;
};

export const HeaderWrapper = styled.header<HeaderProps>`
  display: flex;
  width: 100%;
  min-height: 72px;
  flex-wrap: wrap;
  padding: 16px;

  position: relative;
  z-index: 2;

  ${smMedia(css`
    padding: 16px 40px;
  `)}

  ${mdMedia(css`
    flex-wrap: nowrap;
  `)}

  ::after {
    content: '';
    background-color: ${themeColor('black0')};
    box-shadow: 0 1px 4px rgba(46, 46, 46, 0.08);
    display: block;
    height: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: -1px;
    transition: height 200ms ease-in-out;
    width: 100%;
    z-index: -1;
  }

  ${({ $isSticky, $isDropped }): CSSProp => {
    if ($isSticky) {
      return css`
        ${mdMedia(css`
          position: sticky;
          top: 0;
          z-index: 2;

          ::after {
            height: 72px;
          }
        `)}
      `;
    }

    if ($isDropped) {
      return css`
        margin-top: 80px;

        ${smMedia(css`
          margin-top: 0;
        `)}
      `;
    }

    return css``;
  }}
`;

export const HeaderDivider = styled.div`
  display: flex;
  width: 0;
  margin-left: auto;
`;

export const HeaderLogo = styled.a`
  color: ${themeColor('black80')};
  text-decoration: none;
  margin: auto 0;
  background: url(${themeImages('logo')}) no-repeat center;
  background-size: contain;
  height: 40px;
  width: 123px;
  ${smMedia(css`
    height: 40px;
    width: 153px;
  `)}
`;

export const HeaderHelp = styled.a`
  position: relative;
  margin: auto 0 auto 0.5rem;
  ${smMedia(css`
    margin: auto 0 auto 1rem;
  `)}
`;

export const PanelWrapper = styled.div`
  padding: 12px 24px;
  background: ${themeColor('primary50')};
  margin-bottom: 24px;

  ${smMedia(css`
    padding-left: 64px;
    padding-right: 64px;
    margin-bottom: 32px;
  `)}

  ${mdMedia(css`
    padding-left: 168px;
    padding-right: 168px;
    margin-bottom: 64px;
  `)}
`;

export const PanelButton = styled.a`
  display: inline-flex;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  height: 48px;
  padding: 0 16px;
  align-items: center;
  justify-content: center;
  border-radius: 9px;
  width: 100%;
  text-decoration: none;
  color: ${themeColor('black80')};
  background: ${themeColor('secondary20')};

  ${smMedia(css`
    min-width: 240px;
    width: auto;
  `)}
`;

export const PanelButtonText = styled.span`
  margin-left: 8px;
`;
