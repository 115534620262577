import { DefaultTheme, ThemeSizes, ThemeProps } from 'styled-components';

export const themeSize =
  (sizeName: keyof ThemeSizes): ((props: ThemeProps<DefaultTheme>) => string) =>
  ({ theme }): string => {
    const size = theme.sizes[sizeName];
    if (!size) {
      throw new Error(`Size for ${sizeName} is not defined in Theme`);
    }
    return size;
  };

export default themeSize;
