import { DefaultTheme, ThemeFont, ThemeProps } from 'styled-components';

export const themeFont =
  (
    fontParamName: keyof ThemeFont
  ): ((props: ThemeProps<DefaultTheme>) => string) =>
  ({ theme }): string => {
    const fontParam = theme.font[fontParamName];

    if (!fontParam) {
      throw new Error(
        `Font parameter for ${fontParam} is not defined in Theme`
      );
    }
    return fontParam;
  };

export default themeFont;
