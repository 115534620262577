import { useEffect, useState, useRef, memo, useMemo } from 'react';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import { SUPPORT_NUMBERS } from 'src/constants/support-numbers';
import { RoutePath } from 'src/constants/route-path';

import { Category, Slugs } from 'src/types/blog-article';
import { User } from 'src/models/dto';

import { getCookie, setCookie } from 'src/utils/cookie';

import AppLinks from 'src/components/AppLinks';

import MonoIcon from 'src/components-2.0/MonoIcon';

import { sendGTMEvent } from 'src/utils/gtm';

import Nav from '../Nav/Nav';
import LanguageMenu from '../LanguageMenu';
import ContactUsMenu from '../ContactUsMenu';

import {
  HeaderDivider,
  HeaderHelp,
  HeaderLogo,
  HeaderWrapper,
  PanelWrapper,
  PanelButton,
  PanelButtonText,
} from './header-styled';

const Account = dynamic<User.DTO & { isThankYouPage: boolean }>(
  () => import('../Account/Account').then((mod) => mod.default as any),
  { ssr: false }
);

export interface HeaderProps {
  hideLocaleSwitcher: boolean;
  pathsForSlug: Slugs[] | null;
  // blogCategories: Category[];
  userData?: User.DTO;
  isContactUs: boolean;
  isReturnPanelShown: boolean;
  countryCode: string;
}

const actualRoutes: { [key: string]: string } = {
  '/frequently-asked-questions': 'faq',
  '/about': 'about',
  '/support': 'support',
  '/refund-policy': 'refund',
  '/privacy-policy': 'privacy',
  '/terms-of-service': 'serterms',
  '/subscription-terms': 'subterms',
  '/cookie-policy': 'сookie',
  '/unimeal-editorial-policy': 'editorial',
  '/dmca': 'dmca',
  '/do-not-sell-my-personal-information': 'dnsmpi',
};

function Header({
  hideLocaleSwitcher,
  pathsForSlug,
  userData,
  isContactUs,
  isReturnPanelShown,
  countryCode,
}: HeaderProps): JSX.Element {
  const { t } = useTranslation('common');
  const { pathname, asPath } = useRouter();

  const [startUrl, setStartUrl] = useState('/');
  const [scrollY, setScrollY] = useState(0);

  const scrollTimeoutRef = useRef<any>(null);

  const [isReturnURLDefined, setIsReturnURLDefined] = useState(false);
  const [destination, setDestination] = useState('');

  const isSticky =
    pathname.includes('/final-page') || pathname.includes('/final-learn');
  const returnURLRef = useRef<any>('');

  const isHelpIconVisible = !pathname.includes('/support');
  const isThankYouPage = pathname.includes('/thank-you');
  const isMealPlanPage = pathname.includes('/meal-plan');

  const supportNumber = SUPPORT_NUMBERS[countryCode] || SUPPORT_NUMBERS.USA;

  const contactUsLinks = useMemo(
    () => [
      {
        href: `tel:${supportNumber}`,
        text: `${supportNumber}`,
        icon: `${process.env.ASSETS_PUBLIC_PATH}images/nav/call.svg`,
      },
      {
        href: `mailto:support@unimeal.com`,
        text: `support@unimeal.com`,
        icon: `${process.env.ASSETS_PUBLIC_PATH}images/nav/mail.svg`,
      },
    ],
    [supportNumber]
  );

  useEffect(() => {
    if (
      pathname.startsWith('/quiz') ||
      pathname === '/[step]' ||
      pathname.startsWith('/checkout')
    ) {
      returnURLRef.current = asPath;
      setIsReturnURLDefined(true);
      setCookie('returnURL', asPath);
      setDestination(asPath.indexOf('checkout') > 0 ? 'checkout' : 'quiz');
    }
  }, [asPath, pathname]);

  useEffect(() => {
    const firstStepSlug = getCookie('quizFirstStepUrl');
    const groupUrl = getCookie('groupUrl');

    setStartUrl(`/${firstStepSlug}${groupUrl ? `?group=${groupUrl}` : ''}`);

    if (
      getCookie('returnURL') &&
      !(
        pathname.startsWith('/quiz') ||
        pathname === '/[step]' ||
        asPath.startsWith('/checkout')
      )
    ) {
      returnURLRef.current = asPath;
      setIsReturnURLDefined(true);
      setDestination(
        getCookie('returnURL').indexOf('checkout') > 0 ? 'checkout' : 'quiz'
      );
    }
  }, []);

  useEffect(() => {
    function handleScroll(): void {
      clearTimeout(scrollTimeoutRef.current);

      scrollTimeoutRef.current = setTimeout(() => {
        setScrollY(window.scrollY);
      }, 100);
    }

    if (isSticky) {
      setScrollY(window.scrollY);
      window.addEventListener('scroll', handleScroll);
    }

    return (): void => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isSticky]);

  const isCTAPanelVisible =
    isReturnPanelShown ||
    pathname.includes(RoutePath.FAQ) ||
    pathname.includes(RoutePath.contact) ||
    pathname.includes(RoutePath.about);

  const sendGAEvent = (): void => {
    if (pathname in actualRoutes) {
      sendGTMEvent({ event: `${pathname}_back_${destination}` });
    }
  };

  return (
    <>
      <HeaderWrapper $isSticky={scrollY >= 400 && isSticky}>
        <Link href={startUrl} as={startUrl} passHref>
          <HeaderLogo title="Unimeal" />
        </Link>

        <HeaderDivider />

        {!!userData && (
          <Account
            email={userData.email}
            uuid={userData.uuid}
            isThankYouPage={isThankYouPage}
          />
        )}

        {isContactUs && <ContactUsMenu contactUsLinks={contactUsLinks} />}

        {!hideLocaleSwitcher && <LanguageMenu pathsForSlug={pathsForSlug} />}

        {isHelpIconVisible && (
          <Link href={RoutePath.contact} passHref prefetch={false}>
            <HeaderHelp>
              <MonoIcon
                name="helpCircle"
                sizeRate={3}
                // @ts-ignore
                title={t('header.bubble.h3')}
                aria-label={t('header.bubble.h3')}
                role="button"
              />
            </HeaderHelp>
          </Link>
        )}

        <Nav
          hideLocaleSwitcher={hideLocaleSwitcher}
          pathsForSlug={pathsForSlug}
          countryCode={countryCode}
          supportNumber={supportNumber}
        />
      </HeaderWrapper>

      {isMealPlanPage && <AppLinks />}

      {isReturnURLDefined && isCTAPanelVisible && (
        <PanelWrapper>
          <Link href={getCookie('returnURL')} passHref prefetch={false}>
            <PanelButton
              title={t('article.exhort.buttonText')}
              onClick={sendGAEvent}
            >
              <MonoIcon name="left" sizeRate={2} />
              <PanelButtonText>{t(`back_to_${destination}`)}</PanelButtonText>
            </PanelButton>
          </Link>
        </PanelWrapper>
      )}
    </>
  );
}

export default memo(Header);
