import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

import { themeColor, themeFont } from 'src/theme/utils';

const GlobalStyle = createGlobalStyle`
  ${normalize}

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html {
    font-family: sans-serif;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  body {
    background-color: ${themeColor('black0')};
    font-family: ${themeFont('fontFamily')}, sans-serif;
    color: ${themeColor('black80')};
    font-size: 16px;
    line-height: 1.5;
    min-height: 100vh;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
    list-style-position: inside;
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
  }

  ol,
  ul,
  dl,
  dd {
    margin: 0;
    padding: 0;
  }

  :focus {
    outline: none !important;
  }

  a {
    text-decoration: none;
    background-color: transparent;
  }

  a:hover,
  a:active,
  a:focus {
    outline: 0;
    text-decoration: none;
  }

  fieldset {
    padding: 0;
    margin: 0;
    border: 0;
  }

  legend {
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
  }

  figure {
    margin: 0;
  }

  img {
    vertical-align: middle;
  }

  svg {
    overflow: hidden;
    vertical-align: middle;
  }

  label {
    display: inline-block;
    margin-bottom: 0;
  }

  button {
    border-radius: 0;
    background: none;
    border: none;
    outline: none;
    padding: 0;
  }

  input,
  button,
  select,
  optgroup,
  textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    outline: none;
  }

  button,
  input {
    overflow: visible;
  }

  button,
  select {
    text-transform: none;
  }

  select {
    word-wrap: normal;
  }

  button:not(:disabled),
  [type='button']:not(:disabled),
  [type='reset']:not(:disabled),
  [type='submit']:not(:disabled) {
    cursor: pointer;
  }

  input[type='number'] {
    appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none;
  }

  [hidden] {
    display: none !important;
  }
  
  .ReactModal__Body--open {
    overflow: hidden;
  }
`;

export default GlobalStyle;
