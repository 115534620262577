import { DefaultTheme, ThemeImages, ThemeProps } from 'styled-components';

export const themeImages =
  (
    imageParamName: keyof ThemeImages
  ): ((props: ThemeProps<DefaultTheme>) => string) =>
  ({ theme }): string => {
    const imageParam = theme.images[imageParamName];

    if (!imageParam) {
      throw new Error(
        `Image parameter for ${imageParam} is not defined in Theme`
      );
    }
    return imageParam;
  };

export default themeImages;
