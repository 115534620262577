import styled, { css, CSSProp } from 'styled-components';

type IconWrapperProps = {
  $sizeRate?: number;
};

const IconWrapper = styled.svg<IconWrapperProps>`
  ${({ $sizeRate }): CSSProp => {
    if ($sizeRate) {
      return css`
        height: ${$sizeRate * 8}px;
        min-width: ${$sizeRate * 8}px;
        width: ${$sizeRate * 8}px;
      `;
    }

    return '';
  }}
`;

export default IconWrapper;
