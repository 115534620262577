import styled, { css, CSSProp } from 'styled-components';

import { themeColor, smMedia, themeFont } from 'src/theme/utils';

export const ContactUsMenuWrapper = styled.div`
  margin: auto 0 auto 1rem;
  position: relative;
`;

export const ContactUsMenuToggle = styled.button`
  outline: none;
  box-shadow: none;
  background: none;
  cursor: pointer;
  border: 0;

  &:hover {
    opacity: 0.7;
  }

  ${smMedia(
    css`
      background: rgba(41, 41, 41, 0.04);
      border-radius: 4px;
      padding: 8px 10px;
      display: flex;
      align-items: center;
      min-width: 64px;
      justify-content: space-between;
      color: ${themeColor('black80')};
      font-family: ${themeFont('fontFamily')};
    `
  )}
`;

export const ContactUsMenuCurrent = styled.span`
  display: none;

  ${smMedia(
    css`
      display: block;
      text-transform: capitalize;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
    `
  )}
`;

type ContactUsMenuListProps = {
  $show: boolean;
};

export const ContactUsMenuIcon = styled.span`
  display: none;

  ${smMedia(
    css`
      display: block;
      width: 24px;
      height: 24px;
    `
  )}
`;

export const ContactUsMenuList = styled.div<ContactUsMenuListProps>`
  background: ${themeColor('black0')};
  border-radius: 5px;
  box-shadow: 0px 2px 12px rgba(46, 46, 46, 0.2);
  position: absolute;
  right: -56px;
  top: 100%;
  padding: 24px 0;
  margin-top: 20px;
  z-index: 201;
  ${({ $show }): CSSProp => {
    if ($show) {
      return css`
        display: block;
      `;
    }

    return css`
      display: none;
    `;
  }}

  ${smMedia(
    css`
      width: 375px;
      right: 0;
    `
  )}
`;

export const ContactUsMenuLink = styled.a`
  display: flex;
  align-items: center;
  color: ${themeColor('black80')};
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  padding: 19px 24px;
  position: relative;
  justify-content: stretch;
  width: 100%;
  text-decoration: none;
  &:not(:last-child)::after {
    bottom: 0;
    content: '';
    position: absolute;
    left: 24px;
    right: 24px;
    height: 1px;
    background-color: ${themeColor('black15')};
  }
  &:hover {
    background: ${themeColor('primary0')};
    &:not(:last-child)::after {
      background: ${themeColor('primary0')};
    }
    &::before {
      top: -1px;
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      height: 1px;
      background-color: ${themeColor('primary0')};
    }
  }
`;

export const ContactUsMenuLinkTitle = styled.span`
  padding-right: 40px;
  width: 100%;
`;

export const ContactUsMenuLinkImage = styled.span`
  flex: 0 0 24px;
  margin-top: 4px;
  margin-right: 8px;
`;

export const ContactUsMenuLinkCurrentImage = styled.span`
  flex: 0 0 24px;
  height: 24px;

  ${smMedia(
    css`
      margin-right: 8px;
    `
  )}
`;
