import { memo, useState, useRef, useEffect, useCallback } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import Image from 'next/image';

import { Icon, IconTypes, IconSizes } from 'src/components/Icon';

import { amplitudeLogEvent } from 'src/utils/amplitude';
import { sendGTMEvent } from 'src/utils/gtm';

import {
  ContactUsMenuWrapper,
  ContactUsMenuToggle,
  ContactUsMenuCurrent,
  ContactUsMenuIcon,
  ContactUsMenuList,
  ContactUsMenuLink,
  ContactUsMenuLinkTitle,
  ContactUsMenuLinkImage,
  ContactUsMenuLinkCurrentImage,
} from './contact-us-menu-styled';

type ContactUsMenuProps = {
  contactUsLinks: { href: string; text: string; icon: string }[];
};

function ContactUsMenu({ contactUsLinks }: ContactUsMenuProps): JSX.Element {
  const router = useRouter();

  const wrapperRef = useRef<HTMLDivElement>(null);

  const [showList, toggleList] = useState(false);

  const defaultContactLink = contactUsLinks[0];

  const handleToggle = (): void => {
    toggleList(!showList);
  };

  useEffect(() => {
    function handleDocumentClick(event: MouseEvent): void {
      if (
        showList &&
        event.target !== wrapperRef.current &&
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        toggleList(false);
      }
    }

    document.addEventListener('click', handleDocumentClick);

    return (): void => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [showList]);

  const handlePhoneClick = useCallback((): void => {
    if (router.pathname.includes('/thank-you')) {
      amplitudeLogEvent('phone_typ_en_click');
      sendGTMEvent({ event: 'phone_typ_en_click' });
    }

    if (router.pathname.includes('/meal-plan')) {
      amplitudeLogEvent('meal_en_click');
      sendGTMEvent({ event: 'meal_en_click' });
    }
  }, []);

  return (
    <ContactUsMenuWrapper ref={wrapperRef}>
      <ContactUsMenuToggle
        type="button"
        title={defaultContactLink.text}
        onClick={handleToggle}
      >
        <ContactUsMenuLinkCurrentImage>
          <Image
            src={defaultContactLink.icon}
            alt={defaultContactLink.text}
            width={24}
            height={24}
          />
        </ContactUsMenuLinkCurrentImage>
        <ContactUsMenuCurrent>{defaultContactLink.text}</ContactUsMenuCurrent>
        <ContactUsMenuIcon>
          <Icon type={IconTypes.navDown} size={IconSizes.l} />
        </ContactUsMenuIcon>
      </ContactUsMenuToggle>

      <ContactUsMenuList $show={showList}>
        {contactUsLinks.map(({ href, text, icon }, index) => (
          <Link href={href} passHref key={text}>
            <ContactUsMenuLink title={text} onClick={handleToggle}>
              <ContactUsMenuLinkImage>
                <Image src={icon} alt={text} width={24} height={24} />
              </ContactUsMenuLinkImage>
              <ContactUsMenuLinkTitle
                onClick={index === 0 ? handlePhoneClick : undefined}
              >
                {text}
              </ContactUsMenuLinkTitle>
            </ContactUsMenuLink>
          </Link>
        ))}
      </ContactUsMenuList>
    </ContactUsMenuWrapper>
  );
}

export default memo(ContactUsMenu);
