import { memo, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Head from 'next/head';

import { getCookie, setCookie } from 'src/utils/cookie';

function SendPulseWebPush(): JSX.Element {
  const router = useRouter();

  const [enableWebPush, toggleWebPush] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      const enableWebPushByRouter =
        router.query.pushntf === '1' || router.query.Pushntf === '1';
      const enableWebPushByCookie = getCookie('enableWebPush') === '1';

      const newEnableWebPushStatus =
        enableWebPushByRouter || enableWebPushByCookie;

      if (newEnableWebPushStatus) {
        setCookie('enableWebPush', '1', {
          expires: 365,
        });
      }

      toggleWebPush(newEnableWebPushStatus);
    }, 2500);
  }, []);

  if (enableWebPush) {
    return (
      <Head>
        <script
          src="https://web.webpushs.com/js/push/c0e1e860ff2be12bfbeeb46a99de2875_1.js"
          async
        />
      </Head>
    );
  }

  return <></>;
}

export default memo(SendPulseWebPush);
