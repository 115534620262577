import axios from 'axios';

export const callApi = async (
  endpoint: string,
  params?: { [key: string]: unknown; headers?: { [key: string]: string } }
): Promise<any> => {
  const response = await axios({
    url: `${process.env.NEXT_PUBLIC_API_URL}${endpoint}`,
    ...params,
    headers: {
      'Accept-Language': 'en',
      Authorization: `Bearer ${process.env.NEXT_PUBLIC_AUTH_KEY}`,
      ...(params?.headers || {}),
    },
  });

  return response.data;
};

export default callApi;
