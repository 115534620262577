import { memo, useState, useRef, useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';

import { Icon, IconTypes, IconSizes } from 'src/components/Icon';
import { Slugs } from 'src/types/blog-article';

import {
  LanguageMenuWrapper,
  LanguageMenuToggle,
  LanguageMenuCurrentLang,
  LanguageMenuList,
  LanguageMenuLink,
  LanguageMenuLinkTitle,
} from './language-menu-styled';

type LanguageMenuProps = {
  pathsForSlug: Slugs[] | null;
};

function LanguageMenu({ pathsForSlug }: LanguageMenuProps): JSX.Element {
  const router = useRouter();
  const { t } = useTranslation('common');

  const wrapperRef = useRef<HTMLDivElement>(null);

  const [showList, toggleList] = useState(false);

  const visibleLocales = useMemo(
    () =>
      router.pathname === '/[step]'
        ? (router.locales as string[]).filter((item) => item !== 'it')
        : (router.locales as string[]),
    [router]
  );

  const handleToggle = (): void => {
    toggleList(!showList);
  };

  useEffect(() => {
    function handleDocumentClick(event: MouseEvent): void {
      if (
        showList &&
        event.target !== wrapperRef.current &&
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        toggleList(false);
      }
    }

    document.addEventListener('click', handleDocumentClick);

    return (): void => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [showList]);

  return (
    <LanguageMenuWrapper ref={wrapperRef}>
      <LanguageMenuToggle
        type="button"
        title={router.locale}
        onClick={handleToggle}
      >
        <LanguageMenuCurrentLang>{router.locale}</LanguageMenuCurrentLang>
        <Icon type={IconTypes.navDown} size={IconSizes.l} />
      </LanguageMenuToggle>

      <LanguageMenuList $show={showList}>
        {visibleLocales.map((locale) => {
          const href = pathsForSlug
            ? `/blog/${
                pathsForSlug?.find((path) => path.lang === locale)?.slug || ''
              }`
            : router.asPath;

          return (
            <Link href={href} passHref locale={locale} key={locale}>
              <LanguageMenuLink
                title={t(`langTitle.${locale}`)}
                onClick={handleToggle}
              >
                <LanguageMenuLinkTitle>
                  {t(`langTitle.${locale}`)}
                </LanguageMenuLinkTitle>

                {locale === router.locale && (
                  <Icon type={IconTypes.cmnCheckGreen} size={IconSizes.l} />
                )}
              </LanguageMenuLink>
            </Link>
          );
        })}
      </LanguageMenuList>
    </LanguageMenuWrapper>
  );
}

export default memo(LanguageMenu);
