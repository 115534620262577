import { css, CSSProp } from 'styled-components';

import { themeSize } from './themeSize';

export const xsMobile = (content: CSSProp): CSSProp => css`
  @media (max-width: calc(${themeSize('tablet')} - 409px)) {
    ${content}
  }
`;

export const xsOnlyMedia = (content: CSSProp): CSSProp => css`
  @media (max-width: calc(${themeSize('tablet')} - 0.01px)) {
    ${content}
  }
`;

export const smMedia = (content: CSSProp): CSSProp => css`
  @media (min-width: ${themeSize('tablet')}) {
    ${content}
  }
`;

export const smlMedia = (content: CSSProp): CSSProp => css`
  @media only screen and (min-width: ${themeSize(
      'tablet'
    )}) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
    ${content}
  }
`;

export const mdMedia = (content: CSSProp): CSSProp => css`
  @media (min-width: ${themeSize('desktop')}) {
    ${content}
  }
`;

export const retinaMedia = (content: CSSProp): CSSProp => css`
  @media only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min--moz-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2/1),
    only screen and (min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
    ${content}
  }
`;
