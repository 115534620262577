import styled, { css } from 'styled-components';

import { mdMedia, smMedia, themeColor } from 'src/theme/utils';
import { A } from 'src/components/Text';

export const AppLinksWrapper = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  padding: 20px 16px;
  background: ${themeColor('secondary10')};
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${smMedia(css`
    height: 72px;
    overflow: visible;
    padding: 12px 40px;
  `)}

  ${mdMedia(css`
    padding: 12px 100px 12px 234px;
  `)}
`;

export const AppLinksContainer = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const AppIcon = styled.div`
  ${mdMedia(css`
    position: absolute;
    top: -13px;
    left: 100px;
    height: 98px;
    width: 98px;
  `)};
`;

export const AppLinksText = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.12px;
  margin-right: 10px;
  color: ${themeColor('black80')};

  ${smMedia(css`
    font-size: 16px;
    line-height: 24px;
    width: 198px;
  `)};

  ${mdMedia(css`
    width: 485px;
    font-size: 20px;
    line-height: 26px;
    font-weight: 700;
  `)};
`;

export const MobileAppLink = styled(A)`
  font-family: 'IBM Plex Sans', sans-serif;
  align-items: center;
  border-radius: 8px;
  display: flex;
  font-weight: 500;
  justify-content: center;
  letter-spacing: 0.14px;
  border: 0;
  cursor: pointer;
  font-size: 18px;
  line-height: 28px;
  padding: 6px 16px;
  background-color: ${themeColor('primary50')};
  color: ${themeColor('black0')};
  width: 152px;
  text-align: center;
`;

export const DesktopAppLinksWrapper = styled.div`
  ${smMedia(css`
    display: flex;
    justify-content: space-between;
    width: 418px;
  `)};

  ${mdMedia(css`
    width: 602px;
  `)};
`;

export const DesktopAppLink = styled(A)`
  display: flex;

  ${smMedia(css`
    height: 40px;
    width: 134px;

    &:nth-child(1),
    &:nth-child(2) {
      margin-right: 8px;
    }
  `)}

  ${mdMedia(css`
    height: 56px;
    width: 190px;

    &:nth-child(1),
    &:nth-child(2) {
      margin-right: 16px;
    }
  `)};
`;
