import styled, { css, CSSProp } from 'styled-components';

import { themeColor, smMedia } from 'src/theme/utils';

export const LanguageMenuWrapper = styled.div`
  margin: auto 0 auto 1rem;
  position: relative;
  display: none;
  ${smMedia(
    css`
      display: block;
    `
  )}
`;

export const LanguageMenuToggle = styled.button`
  background: rgba(41, 41, 41, 0.04);
  border-radius: 4px;
  padding: 7px 8px;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  box-shadow: none;
  border: 0;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  min-width: 64px;
  justify-content: space-between;
  color: ${themeColor('black80')};
  &:hover {
    opacity: 0.7;
  }
`;

export const LanguageMenuCurrentLang = styled.span`
  text-transform: capitalize;
`;

type LanguageMenuListProps = {
  $show: boolean;
};

export const LanguageMenuList = styled.div<LanguageMenuListProps>`
  background: ${themeColor('black0')};
  border-radius: 5px;
  box-shadow: 0px 2px 12px rgba(46, 46, 46, 0.2);
  position: absolute;
  right: 0;
  top: 100%;
  padding: 24px 0;
  margin-top: 20px;
  width: 375px;
  z-index: 201;
  ${({ $show }): CSSProp => {
    if ($show) {
      return css`
        display: block;
      `;
    }

    return css`
      display: none;
    `;
  }}
`;

export const LanguageMenuLink = styled.a`
  display: flex;
  align-items: center;
  color: ${themeColor('black80')};
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  padding: 19px 24px;
  position: relative;
  justify-content: stretch;
  width: 100%;
  text-decoration: none;
  &:not(:last-child)::after {
    bottom: 0;
    content: '';
    position: absolute;
    left: 24px;
    right: 24px;
    height: 1px;
    background-color: ${themeColor('black15')};
  }
  &:hover {
    background: ${themeColor('primary0')};
    &:not(:last-child)::after {
      background: ${themeColor('primary0')};
    }
    &::before {
      top: -1px;
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      height: 1px;
      background-color: ${themeColor('primary0')};
    }
  }
`;

export const LanguageMenuLinkTitle = styled.span`
  padding-right: 40px;
  width: 100%;
`;
