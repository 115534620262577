import {
  NewUserProfile,
  NewUserProfileData,
  UserBMIParams,
  UserLearnValues,
} from 'src/types';

import { callApi } from 'src/utils/callApi';

export const createUserProfile = async ({
  profileValues,
  locale,
}: {
  profileValues: NewUserProfile;
  locale: string;
}): Promise<{ userId: string }> => {
  const { userId } = await callApi('/v4.0/user', {
    method: 'post',
    data: profileValues,
    headers: {
      'Accept-Language': locale,
    },
  });

  return { userId };
};

export const updateUserKnownledge = async ({
  userId,
  learnValues,
  locale,
}: {
  userId: string;
  learnValues: UserLearnValues;
  locale: string;
}): Promise<void> => {
  await callApi(`/v4.0/user/knowledge/${userId}`, {
    method: 'put',
    data: learnValues,
    headers: {
      'Accept-Language': locale,
    },
  });
};

export const getUserProfile = async ({
  userId,
  locale,
}: {
  userId: string;
  locale: string;
}): Promise<NewUserProfileData> => {
  const newUserProfileData = await callApi(`/v4.0/user/${userId}`, {
    method: 'get',
    headers: {
      'accept-language': locale,
    },
  });

  return newUserProfileData;
};

export const getUserBMI = async ({
  userBMIParams: {
    gender,
    measureUnit,
    currentWeight,
    targetWeight,
    height,
    age,
  },
  locale,
}: {
  userBMIParams: UserBMIParams;
  locale: string;
}): Promise<any> => {
  const response = await callApi('/v4.0/calculate/healthy', {
    method: 'post',
    data: {
      gender,
      measureUnit,
      currentWeight,
      targetWeight,
      height,
      age,
    },
    headers: {
      'Accept-Language': locale,
    },
  });

  return response;
};

export const getUserLearnScore = async ({
  learnValues,
  locale,
}: {
  learnValues: UserLearnValues;
  locale: string;
}): Promise<{ score: number }> => {
  const response = await callApi('/v4.0/calculate/knowledge', {
    method: 'post',
    data: learnValues,
    headers: {
      'Accept-Language': locale,
    },
  });

  return response;
};

export const getUserCountryInfo = async (): Promise<{
  countryCode: string;
  showModalWindow: boolean;
}> => {
  try {
    return await callApi('/v4.0/user/country-info');
  } catch (error) {
    return {
      countryCode: '',
      showModalWindow: false,
    };
  }
};

export const getUserOrderInfo = async ({
  orderId,
}: {
  orderId: string;
}): Promise<{
  email: string;
  uuid: string;
}> => {
  const response = await callApi(`/v4.0/user/order/${orderId}`, {
    method: 'get',
  });

  return response;
};
