export const getLocalStorageItem = (name: string): any => {
  try {
    const { value, expireAt } = JSON.parse(
      window.localStorage.getItem(name) || ''
    );

    if (!expireAt) {
      return value;
    }

    const currentDateTime = Date.now();

    return currentDateTime - expireAt > 0 ? null : value;
  } catch (error) {
    return null;
  }
};

export const setLocalStorageItem = (
  name: string,
  value: any,
  maxAge?: number
): void => {
  try {
    if (name) {
      window.localStorage.setItem(
        name,
        JSON.stringify({
          value,
          expireAt: maxAge ? Date.now() + maxAge : null,
        })
      );
    }
  } catch (error) {
    console.error(`Error: unable to save ${name} in localStorage`);
  }
};
