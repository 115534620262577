module.exports = {
  locales: ['de', 'en', 'es', 'fr', 'pt', 'ru', 'it'],
  defaultLocale: 'en',
  localeDetection: false,
  pages: {
    '*': ['common'],
    '/onboarding': ['homepage'],
    '/final/[orderId]': ['final'],
    '/final-quiz/[userId]': ['final', 'quizCommon', 'new-final'],
    '/pricing/[orderId]': ['pricing'],
    '/min-checkout/[orderId]/[productId]/[pp]/[pt]/[wu]/[paymentTypes]': [
      'checkout',
    ],
    '/agp-checkout/[orderId]/[productId]/[pp]/[pt]/[wu]/[paymentTypes]': [
      'checkout',
    ],
    '/checkout/[orderId]/[productId]/[pp]/[pt]/[wu]/[paymentTypes]': [
      'checkout',
    ],
    '/checkout-page/[userId]/[productId]/[pp]/[pt]/[wu]/[paymentTypes]': [
      'checkout',
    ],
    '/thank-you/[uuid]': ['thank-you'],
    '/thank-you-page/[orderId]': ['thank-you'],
    '/email-thank-you-page': ['thank-you'],
    '/meal-plan': ['meal-plan'],
    '/meal-plan/[id]': ['meal-plan'],
    '/meal-error': ['meal-plan'],
    '/[step]': ['quiz'],
    '/frequently-asked-questions': ['faq'],
    '/about': ['about'],
    '/': ['homepage'],
    '/final-page/[userId]': ['final', 'new-final'],
    '/signin': ['signin'],
    '/signin/[url]': ['signin'],
    '/lp1': ['lp1'],
    '/lp2': ['lp2'],
    '/lp3': ['lp3'],
    '/lp6': ['lp6'],
    '/final-learn/[userId]': ['new-final'],
    '/final-shrt/[userId]': ['quizCommon', 'final', 'pricing'],
    '/pricing-page/[userId]': ['pricing', 'pricing-2.0'],
    '/pricing-page-nu/[userId]': ['pricing', 'pricing-2.0'],
    '/pricing-learn/[userId]': ['pricing', 'pricing-2.0'],
    '/pricing-learn-nu/[userId]': ['pricing', 'pricing-2.0'],
    '/pricing-quiz/[userId]': ['pricing', 'pricing-2.0'],
    '/pricing-quiz-nu/[userId]': ['pricing', 'pricing-2.0'],
    '/new-quiz': ['quizCommon'],
    '/new-quiz/step-goal': ['quizCommon'],
    // todo remove quiz namespace here (when move email consent modal texts to quizCommon namespace)
    '/new-quiz/[stepId]': ['quizCommon', 'quiz'],
    '/learn-quiz': ['quizCommon'],
    '/learn-quiz/step-goal': ['quizCommon'],
    // todo remove quiz namespace here (when move email consent modal texts to quizCommon namespace)
    '/learn-quiz/[stepId]': ['quizCommon', 'quiz'],
    '/quiz': ['quizCommon'],
    '/quiz/step-goal': ['quizCommon'],
    // todo remove quiz namespace here (when move email consent modal texts to quizCommon namespace)
    '/quiz/[stepId]': ['quizCommon', 'quiz'],
    '/shrt': ['quizCommon'],
    '/shrt/step-gender': ['shrtQuizHome'],
    // todo remove quiz namespace here (when move email consent modal texts to quizCommon namespace)
    '/shrt/[stepId]': ['quizCommon', 'quiz'],
    '/result/[orderId]': ['final-result'],
    '/my-subscription': ['my-subscription'],
    '/final-result/[orderId]': ['final-result'],
    '/intro-final/[orderId]': ['final', 'pricing'],
    '/support': ['support'],
  },

  loadLocaleFrom: (locale, namespace) =>
    import(`./src/translations/${namespace}/${locale}`).then((m) => m.default),
};
