import { DefaultTheme, ThemeColors, ThemeProps } from 'styled-components';

export const themeColor =
  (
    colorName: keyof ThemeColors
  ): ((props: ThemeProps<DefaultTheme>) => string) =>
  ({ theme }): string => {
    const color = theme.colors[colorName];

    if (!color) {
      throw new Error(`Color for ${colorName} is not defined in Theme`);
    }
    return color;
  };

export default themeColor;
