import styled from 'styled-components';

import { themeColor, themeFont } from 'src/theme/utils';

export const HHero = styled.h1`
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 124%;
  letter-spacing: -0.3px;
  color: ${themeColor('black80')};
`;

export const H1 = styled.h1`
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 44px;
  letter-spacing: -0.2px;
  color: ${themeColor('black80')};
`;

export const H2 = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 36px;
  letter-spacing: -0.15px;
  color: ${themeColor('black80')};
`;

export const H3 = styled.h3`
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: -0.1px;
  color: ${themeColor('black80')};
`;

export const H4 = styled.h4`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.1px;
  color: ${themeColor('black80')};
`;

export const Text1 = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: ${themeColor('black80')};
`;

export const Text1Bold = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: ${themeColor('black80')};
`;

export const Text2 = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: ${themeColor('black80')};
`;

export const Text2Bold = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${themeColor('black80')};
`;

export const Caption = styled.p`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.12px;
  color: ${themeColor('black50')};
`;

export const CaptionSmall = styled.p`
  color: ${themeColor('black50')};
  font-size: 12px;
  letter-spacing: 0.2px;
  line-height: 18px;
`;

export const ButtonTextBig = styled.p`
  font-family: ${themeFont('fontFamily')};
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  color: ${themeColor('black80')};
  letter-spacing: 0.14px;
`;

export const ButtonTextSmall = styled.p`
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  color: ${themeColor('black80')};
  letter-spacing: 0.12px;
`;

export const LinkText = styled.p`
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;
  color: ${themeColor('black80')};
`;

export const A = styled.a`
  text-decoration: none;
  color: ${themeColor('primary50')};
`;
