// eslint-disable-next-line import/prefer-default-export
export enum RoutePath {
  quiz = '/',
  blog = '/blog',
  authors = '/authors',
  contactUs = '/contact-us',
  accessMealPlan = '/meal-access',
  mealPlan = '/meal-plan',
  mySubscription = '/my-subscription',
  FAQ = '/frequently-asked-questions',
  refundPolicy = '/refund-policy',
  privacyPolicy = '/privacy-policy',
  termsOfService = '/terms-of-service',
  subscriptionTerms = '/subscription-terms',
  cookiePolicy = '/cookie-policy',
  stepGoal = '/step-goal',
  unimealEditorialPolicy = '/unimeal-editorial-policy',
  dmca = '/dmca',
  doNotSellMyPersonalInformation = '/do-not-sell-my-personal-information',
  about = '/about',
  contact = '/support',
}
