import { memo } from 'react';
import Head from 'next/head';
import useTranslation from 'next-translate/useTranslation';

type OpenGraphProps = {
  title: string;
  description: string;
  ogDescription?: string;
  img: string;
  url: string;
  noIndex: boolean;
  isWithChat: boolean;
};

function OpenGraph({
  title,
  description,
  ogDescription,
  url,
  img,
  noIndex,
  isWithChat,
}: OpenGraphProps): JSX.Element {
  const { t } = useTranslation('common');

  const siteTitle = `${title ? `${title} — ` : ''}${
    process.env.NEXT_PUBLIC_MIRROR_TITLE || 'Unimeal'
  }`;

  return (
    <Head>
      <title>{siteTitle}</title>
      {!!description && <meta name="description" content={description} />}

      <meta property="og:title" content={siteTitle} />
      <meta
        property="og:description"
        content={
          ogDescription ||
          description ||
          `563,761 ${t('meta.description.users')}`
        }
      />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={img} />

      {noIndex && <meta name="robots" content="noindex" />}

      {!!process.env.NEXT_PUBLIC_PINTEREST_DOMAIN_VERIFY_ID && (
        <meta
          name="p:domain_verify"
          content={process.env.NEXT_PUBLIC_PINTEREST_DOMAIN_VERIFY_ID}
        />
      )}

      {!!process.env.NEXT_PUBLIC_GOOGLE_SITE_VERIFICATION && (
        <meta
          name="google-site-verification"
          content={process.env.NEXT_PUBLIC_GOOGLE_SITE_VERIFICATION}
        />
      )}

      {!!isWithChat && !!process.env.NEXT_PUBLIC_ZENDESK_KEY && (
        <script
          async
          id="ze-snippet"
          src={`https://static.zdassets.com/ekr/snippet.js?key=${process.env.NEXT_PUBLIC_ZENDESK_KEY}`}
        />
      )}
    </Head>
  );
}

export default memo(OpenGraph);
