import { NextRouter } from 'next/router';

import { getCookie } from './cookie';

import { getUrlParamValue } from './url';

type ABTestOption = {
  url: string;
  weight: number;
};

type ABTestCondition = {
  [key: string]: string | string[];
} & { locale?: string[] };

export type UserAbTestsState = {
  [abTestName: string]: string;
};

// ab tests config. add new ab test config there:
export const abTests: {
  [abTestName: string]: {
    condition: ABTestCondition[];
    path: string[];
    coverage: number; // from 0 to 1
    options: ABTestOption[];
  };
} = {
  // abtest name
  'start-goal': {
    // ab test start path
    path: ['/step-goal'],
    // condition for start ab test
    condition: [
      {
        locale: ['es'],
        pp: 'um-trial-12',
        utm_source: '*',
        ptm: 'nuv3',
      },
      {
        locale: ['es'],
        pricePackageId: 'um-trial-12',
        utm_source: '*',
        ptm: 'nuv3',
      },
    ],
    coverage: 0.5,
    // redirect urls
    options: [
      // {
      //   url: '/weight_loss-made-easy?group=start-4&expName=STARTPAGE&expGrp=5',
      //   weight: 1,
      // },
      // {
      //   url: '/weight_loss-made-easy2?group=start-3&expName=STARTPAGE&expGrp=4',
      //   weight: 1,
      // },
      // {
      //   url: '/healthy_nutrition-made_simple2?group=start-2&expName=STARTPAGE&expGrp=3',
      //   weight: 1,
      // },
      {
        url: '/top_priority?group=start-1&expName=STARTPAGE&expGrp=2',
        weight: 1,
      },
      {
        url: '/step-goal?expName=STARTPAGE&expGrp=1',
        weight: 1,
      },
    ],
  },
};

export function getActiveABTestOption(
  router: NextRouter,
  userAbTestsState: UserAbTestsState
): { testName: keyof typeof abTests; option: ABTestOption } | null {
  const randomValue = Math.random();
  const activeAbTestName = Object.keys(abTests).find(
    (abTestName) =>
      !abTests[abTestName].condition.length ||
      abTests[abTestName].condition.some((condition) =>
        Object.keys(condition).every((key) => {
          const conditionKeyValue =
            key === 'locale'
              ? (router.locale as string)
              : getUrlParamValue(key, router.asPath) || getCookie(key);

          // eslint-disable-next-line no-nested-ternary
          return Array.isArray(condition[key])
            ? condition[key]?.includes(conditionKeyValue)
            : condition[key] === '*'
            ? !!conditionKeyValue
            : condition[key] === conditionKeyValue;
        })
      )
  );

  const activeAbTestParams = activeAbTestName
    ? abTests[activeAbTestName]
    : null;

  // return null if no one ab test for current url was found
  if (
    !activeAbTestName ||
    !activeAbTestParams ||
    !activeAbTestParams.path.includes(router.asPath.split('?')[0]) ||
    activeAbTestParams.coverage <= randomValue
  ) {
    return null;
  }

  const userActiveAbTestParamsOption = abTests[activeAbTestName].options.find(
    (option) => userAbTestsState[activeAbTestName] === option.url
  );

  // return user defined ab test resut url if it is defined (saved to local storage)
  if (userActiveAbTestParamsOption) {
    return {
      testName: activeAbTestName,
      option: userActiveAbTestParamsOption,
    };
  }

  const isAllWeightsEqual = activeAbTestParams.options.every(
    ({ weight }) => weight === activeAbTestParams.options[0].weight
  );

  if (isAllWeightsEqual) {
    return {
      testName: activeAbTestName,
      option:
        activeAbTestParams.options[
          Math.floor(
            randomValue *
              activeAbTestParams.options.length *
              (1 / activeAbTestParams.coverage)
          )
        ],
    };
  }

  const optionsWeightSumm = activeAbTestParams.options.reduce(
    (result, { weight }) => weight + result,
    0
  );

  const optionsWithProbability = activeAbTestParams.options.map((option) => ({
    ...option,
    probability:
      (option.weight * activeAbTestParams.coverage) / optionsWeightSumm,
  }));

  let s = 0;
  const lastIndex = activeAbTestParams.options.length - 1;

  for (let i = 0; i < lastIndex; i += 1) {
    s += optionsWithProbability[i].probability;

    if (randomValue < s) {
      return {
        testName: activeAbTestName,
        option: activeAbTestParams.options[i],
      };
    }
  }

  return {
    testName: activeAbTestName,
    option: activeAbTestParams.options[lastIndex],
  };
}

export function getActiveAbTestsPaths(): string[] {
  const enabledAbTests = (process.env.ENABLED_AB_TESTS || '').split(',');

  const enabledAbTestsPaths = Object.keys(abTests).reduce(
    (result, abTestName) => {
      if (enabledAbTests.includes(abTestName)) {
        result.push(...abTests[abTestName].path);
      }

      return result;
    },
    [] as string[]
  );

  return enabledAbTestsPaths;
}
