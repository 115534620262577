import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import { themeImages } from 'src/theme/utils';

import { IconTypes } from './constants/icons';
import { IconSizes } from './constants/sizes';

export interface IconStyledProps {
  iconType?: IconTypes;
  iconUrl?: string;
  $size?: IconSizes;
}

export const iconSources: Record<IconTypes, string | any> = {
  [IconTypes.navMenu]: '/icons/nav_menu.svg',
  [IconTypes.navCross]: '/icons/nav_cross.svg',
  [IconTypes.navCrossCircle]: '/icons/nav_cross_circle.svg',
  [IconTypes.navCrossShadow]: '/icons/nav_cross_shadow.svg',
  [IconTypes.navLeft]: '/icons/nav_left.svg',
  [IconTypes.navRight]: '/icons/nav_right.svg',
  [IconTypes.navLeftCircle]: '/icons/nav_left_circle.svg',
  [IconTypes.navRightCircle]: '/icons/nav_right_circle.svg',
  [IconTypes.navSmallLeft]: '/icons/nav_small_left.svg',
  [IconTypes.navSmallRight]: '/icons/nav_small_right.svg',
  [IconTypes.navArrowRightAlt]: '/icons/nav_arrow_right_alt.svg',
  [IconTypes.navUp]: '/icons/nav_up.svg',
  [IconTypes.navDown]: '/icons/nav_down.svg',
  [IconTypes.navOpen]: '/icons/nav_open.svg',
  [IconTypes.navOpenGreen]: '/icons/nav_open_green.svg',
  [IconTypes.navOpenOrange]: '/icons/nav_open_orange.svg',
  [IconTypes.navOpenBlue]: '/icons/nav_open_blue.svg',
  [IconTypes.navOpenIndigo]: '/icons/nav_open_indigo.svg',
  [IconTypes.navOpenTheme]: themeImages('iconNavOpen'),
  [IconTypes.navClose]: '/icons/nav_close.svg',
  [IconTypes.navCloseGreen]: '/icons/nav_close_green.svg',
  [IconTypes.navCloseOrange]: '/icons/nav_close_orange.svg',
  [IconTypes.navCloseBlue]: '/icons/nav_close_blue.svg',
  [IconTypes.navCloseIndigo]: '/icons/nav_close_indigo.svg',
  [IconTypes.navCloseTheme]: themeImages('iconNavClose'),
  [IconTypes.pbarGender]: '/icons/pbar_gender.svg',
  [IconTypes.pbarGoal]: '/icons/pbar_goal.svg',
  [IconTypes.pbarDesiredWeight]: '/icons/pbar_desired_weight.svg',
  [IconTypes.pbarMeasures]: '/icons/pbar_measures.svg',
  [IconTypes.pbarPear]: '/icons/pbar_pear.svg',
  [IconTypes.pbarTypicalDay]: '/icons/pbar_typical_day.svg',
  [IconTypes.pbarStomachDiscomfort]: '/icons/pbar_stomach_discomfort.svg',
  [IconTypes.pbarStomachHeaviness]: '/icons/pbar_stomach_heaviness.svg',
  [IconTypes.pbarTypicalRation]: '/icons/pbar_typical_ration.svg',
  [IconTypes.pbarIdealWeight]: '/icons/pbar_ideal_weight.svg',
  [IconTypes.pbarEatingHabits]: '/icons/pbar_eating_habits.svg',
  [IconTypes.pbarFitness]: '/icons/pbar_fitness.svg',
  [IconTypes.pbarEnergyLevel]: '/icons/pbar_energy_level.svg',
  [IconTypes.pbarSleep]: '/icons/pbar_sleep.svg',
  [IconTypes.pbarWater]: '/icons/pbar_water.svg',
  [IconTypes.pbarCookingTime]: '/icons/pbar_cooking_time.svg',
  [IconTypes.pbarRestrictions]: '/icons/pbar_restrictions.svg',
  [IconTypes.pbarVeggies]: '/icons/pbar_veggies.svg',
  [IconTypes.pbarProducts]: '/icons/pbar_products.svg',
  [IconTypes.pbarMeat]: '/icons/pbar_meat.svg',
  [IconTypes.rbSelected]: '/icons/rb_selected.svg',
  [IconTypes.rbUnselected]: '/icons/rb_unselected.svg',
  [IconTypes.cmnError]: '/icons/cmn_error.svg',
  [IconTypes.cmnFilled]: '/icons/cmn_filled.svg',
  [IconTypes.cmnFilledInverse]: '/icons/cmn_filled_inverse.svg',
  [IconTypes.cmnFilledInverseTheme]: themeImages('iconFilledInverse'),
  [IconTypes.cmnFilledInverseOrange]: '/icons/cmn_filled_inverse_orange.svg',
  [IconTypes.cmnHelpCircle]: '/icons/cmn_help_circle.svg',
  [IconTypes.cmnPlusCircle]: '/icons/cmn_plus_circle.svg',
  [IconTypes.cmnImagePlaceholder]: '/icons/cmn_image_placeholder.svg',
  [IconTypes.cmnCheck]: '/icons/cmn_check.svg',
  [IconTypes.cmnCheckDefault]: '/icons/cmn_check_default.svg',
  [IconTypes.cmnCheckActive]: themeImages('iconCheck'),
  [IconTypes.cmnCheckActiveGreen]: '/icons/cmn_check_active_green.svg',
  [IconTypes.cmnCheckActiveOrange]: '/icons/cmn_check_active_orange.svg',
  [IconTypes.cmnCheckActiveBlue]: '/icons/cmn_check_active_blue.svg',
  [IconTypes.cmnCheckActiveIndigo]: '/icons/cmn_check_active_indigo.svg',
  [IconTypes.cmnEdit]: '/icons/cmn_edit.svg',
  [IconTypes.cmnSms]: '/icons/cmn_sms.svg',
  [IconTypes.cmnChain]: '/icons/cmn_chain.svg',
  [IconTypes.cmnYoutube]: '/icons/cmn_youtube.svg',
  [IconTypes.qzOffice]: '/icons/qz_office.svg',
  [IconTypes.qzWalk]: '/icons/qz_walk.svg',
  [IconTypes.qzFarmer]: '/icons/qz_farmer.svg',
  [IconTypes.qzHome]: '/icons/qz_home.svg',
  [IconTypes.qzFire]: '/icons/qz_fire.svg',
  [IconTypes.qzApple]: '/icons/qz_apple.svg',
  [IconTypes.qzEnergy]: '/icons/qz_energy.svg',
  [IconTypes.qzSandwich]: '/icons/qz_sandwich.svg',
  [IconTypes.qzNight]: '/icons/qz_night.svg',
  [IconTypes.qzCake]: '/icons/qz_cake.svg',
  [IconTypes.qzSoda]: '/icons/qz_soda.svg',
  [IconTypes.qzSalt]: '/icons/qz_salt.svg',
  [IconTypes.qzYoga]: '/icons/qz_yoga.svg',
  [IconTypes.qzYes]: '/icons/qz_yes.svg',
  [IconTypes.qzNo]: '/icons/qz_no.svg',
  [IconTypes.qzWarning]: '/icons/qz_warning.svg',
  [IconTypes.qzSteady]: '/icons/qz_steady.svg',
  [IconTypes.qzTired]: '/icons/qz_tired.svg',
  [IconTypes.qzSleepy]: '/icons/qz_sleepy.svg',
  [IconTypes.qzHandshake]: '/icons/qz_handshake.svg',
  [IconTypes.qzPotato]: '/icons/qz_potato.svg',
  [IconTypes.qzPeas]: '/icons/qz_peas.svg',
  [IconTypes.qzCouscous]: '/icons/qz_couscous.svg',
  [IconTypes.qzRice]: '/icons/qz_rice.svg',
  [IconTypes.qzMushroom]: '/icons/qz_mushroom.svg',
  [IconTypes.qzQuinoa]: '/icons/qz_quinoa.svg',
  [IconTypes.qzEgg]: '/icons/qz_egg.svg',
  [IconTypes.qzYogurt]: '/icons/qz_yogurt.svg',
  [IconTypes.qzMilk]: '/icons/qz_milk.svg',
  [IconTypes.qzCottageCheese]: '/icons/qz_cottage_cheese.svg',
  [IconTypes.qzTofu]: '/icons/qz_tofu.svg',
  [IconTypes.qzRolledOats]: '/icons/qz_rolled_oats.svg',
  [IconTypes.qzHummus]: '/icons/qz_hummus.svg',
  [IconTypes.qzNoodles]: '/icons/qz_noodles.svg',
  [IconTypes.qzPlantMilk]: '/icons/qz_plant_milk.svg',
  [IconTypes.qzTurkey]: '/icons/qz_turkey.svg',
  [IconTypes.qzFish]: '/icons/qz_fish.svg',
  [IconTypes.qzBeef]: '/icons/qz_beef.svg',
  [IconTypes.qzChicken]: '/icons/qz_chicken.svg',
  [IconTypes.qzPork]: '/icons/qz_pork.svg',
  [IconTypes.qzPear]: '/icons/qz_pear.svg',
  [IconTypes.qzSquare]: '/icons/qz_square.svg',
  [IconTypes.qzHourglass]: '/icons/qz_hourglass.svg',
  [IconTypes.qzHeart]: '/icons/qz_heart.svg',
  [IconTypes.qzMoney]: '/icons/qz_money.svg',
  [IconTypes.qzExclamation]: '/icons/qz_exclamation.svg',
  [IconTypes.navArrowRight]: '/icons/nav_arrow_right.svg',
  [IconTypes.navArrowRightActive]: '/icons/nav_arrow_right_active.svg',
  [IconTypes.cmnCheckGreen]: '/icons/cmn_check_green.svg',
  [IconTypes.broccoli]: '/icons/broccoli.svg',
};

const iconSource = ({ iconType, iconUrl }: IconStyledProps): string =>
  iconUrl || (iconType ? iconSources[iconType] || '' : '');

const sizes: Record<IconSizes, FlattenSimpleInterpolation> = {
  [IconSizes.wm]: css`
    width: 24px;
    height: 16px;
  `,
  [IconSizes.m]: css`
    width: 18px;
    height: 18px;
    flex: 0 0 18px;
  `,
  [IconSizes.l]: css`
    width: 24px;
    height: 24px;
    flex: 0 0 24px;
  `,
  [IconSizes.xl]: css`
    width: 32px;
    height: 32px;
    flex: 0 0 32px;
  `,
  [IconSizes.xxl]: css`
    width: 40px;
    height: 40px;
    flex: 0 0 40px;
  `,
};

const iconSize = ({ $size }: IconStyledProps): FlattenSimpleInterpolation =>
  $size ? sizes[$size] || css`` : css``;

const iconStyles = css`
  display: inline-block;
  border: none;
  background: transparent;
  background: url(${iconSource}) no-repeat center;
  background-size: ${({ iconUrl }): string => (iconUrl ? 'contain' : 'cover')};
  margin-top: auto;
  margin-bottom: auto;
  ${iconSize}
`;

export const IconStyled = styled.span<IconStyledProps>`
  ${iconStyles}
`;

export const IconClickableStyled = styled.button<IconStyledProps>`
  cursor: pointer;
  outline: none;
  ${iconStyles}
`;
