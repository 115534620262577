/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-comment-textnodes */
import Link from 'next/link';
import { memo, useState, useCallback } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { useAmp } from 'next/amp';
import Image from 'next/image';

import { ProductData } from 'src/constants/product-data';
import { Icon } from 'src/components/Icon/Icon';
import MonoIcon from 'src/components-2.0/MonoIcon';
import { IconSizes, IconTypes } from 'src/components/Icon/constants';
import { Caption } from 'src/components/Text';
import { Slugs } from 'src/types/blog-article';

import { amplitudeLogEvent } from 'src/utils/amplitude';
import { sendGTMEvent } from 'src/utils/gtm';

import { MENU_LINKS, TERMS_LINKS } from './constants/menu-links';
import {
  NavSideClose,
  NavSideContactUs,
  NavSideContainer,
  NavSideLinkItem,
  NavSideLinkItemInner,
  NavSideLinks,
  NavSideBackground,
  NavWrapper,
  ChevronIcon,
  MailIcon,
  NavSideContactUsPhone,
  NavSideContactUsPhoneLink,
  NavSideContactUsPhoneCaption,
  NavSideContactUsEmailLink,
  NavSideContactUsEmail,
} from './nav-styled';

export interface NavProps {
  hideLocaleSwitcher: boolean;
  pathsForSlug: Slugs[] | null;
  countryCode: string;
  supportNumber: string;
}

function Nav({
  hideLocaleSwitcher,
  pathsForSlug,
  countryCode,
  supportNumber,
}: NavProps): JSX.Element {
  const [isNavVisible, setIsNavVisible] = useState(false);
  const [isLangVisible, setIsLangVisible] = useState(false);
  const [isTermsVisible, setIsTermsVisible] = useState(false);
  const [isSubsVisible, setIsSubsVisible] = useState(false);

  const { t } = useTranslation('common');
  const router = useRouter();
  const isAmp = useAmp();

  // eslint-disable-next-line array-callback-return,consistent-return
  const menuItems = MENU_LINKS.filter((item) => {
    if (item.id === 'about') {
      return false;
    }
    if (item.id !== 'authors') return item.id !== 'blog';
  });

  const termsItems = TERMS_LINKS.filter((item) => {
    return (
      item.id !== 'dmca' &&
      item.id !== 'doNotSellMyPersonalInformation' &&
      item.id !== 'unimealEditorialPolicy'
    );
  });

  const supportEmail = `support@${process.env.NEXT_PUBLIC_HOST}`;
  const contactUsMailto = `mailto:${supportEmail}`;

  const toggleHandler = useCallback((): void => {
    setIsNavVisible(!isNavVisible);
    setIsLangVisible(false);
    setIsTermsVisible(false);
  }, [isNavVisible]);

  const handleLocaleClick = (locale: string): void => {
    const href = pathsForSlug
      ? `/blog/${
          pathsForSlug?.find((path) => path.lang === locale)?.slug || ''
        }`
      : router.asPath;

    router.push(href, href, { locale });

    setIsNavVisible(false);
    setIsLangVisible(false);
  };

  const handlePhoneClick = useCallback((): void => {
    amplitudeLogEvent('phone_menu_en_click');
    sendGTMEvent({ event: 'phone_menu_en_click' });
  }, []);

  const subsClickHandler = (): void => {
    setIsSubsVisible((prev) => !prev);
  };

  const termsClickHandler = (): void => {
    setIsTermsVisible((prev) => !prev);
  };

  return (
    <NavWrapper>
      <Icon
        type={IconTypes.navMenu}
        size={IconSizes.l}
        onClick={toggleHandler}
        // @ts-ignore
        title={t('header.nav.navigation')}
        aria-label={t('header.nav.navigation')}
      />

      <NavSideBackground isNavVisible={isNavVisible} onClick={toggleHandler} />

      <NavSideContainer isNavVisible={isNavVisible}>
        <NavSideClose>
          <Icon
            type={IconTypes.navCross}
            size={IconSizes.l}
            onClick={toggleHandler}
            // @ts-ignore
            title={t('header.nav.navigation')}
            aria-label={t('header.nav.navigation')}
          />
        </NavSideClose>

        <NavSideLinks>
          {menuItems &&
            menuItems.map(
              (item): JSX.Element => (
                <NavSideLinkItem key={item.id} isActive={false}>
                  <Link href={`${item.href}`} passHref prefetch={false}>
                    <a onClick={toggleHandler} role="presentation">
                      {t(`header.nav.${item.id}`)}
                    </a>
                  </Link>
                </NavSideLinkItem>
              )
            )}

          <NavSideLinkItem
            isActive={isTermsVisible}
            onClick={termsClickHandler}
          >
            <span>{t('header.nav.termsAndPolicies')}</span>
            <ChevronIcon isOpen={isTermsVisible} />
          </NavSideLinkItem>

          {isTermsVisible &&
            termsItems &&
            termsItems.map((item) => (
              <NavSideLinkItemInner key={item.id}>
                <Link href={`${item.href}`} passHref prefetch={false}>
                  <a
                    onClick={toggleHandler}
                    role="presentation"
                    title={t(`header.nav.${item.id}`)}
                  >
                    {t(`header.nav.${item.id}`)}
                    {item.href === router.pathname && (
                      <MonoIcon name="checkSign" />
                    )}
                  </a>
                </Link>
              </NavSideLinkItemInner>
            ))}

          {!hideLocaleSwitcher && (
            <>
              <NavSideLinkItem
                isActive={isLangVisible}
                className="lang"
                onClick={(): void => setIsLangVisible((prev) => !prev)}
              >
                <span>{t('header.nav.language')}</span>
                <ChevronIcon isOpen={isLangVisible} />
              </NavSideLinkItem>

              {isLangVisible &&
                (router.locales as string[]).map((locale) => (
                  <NavSideLinkItemInner
                    key={locale}
                    onClick={(): void => handleLocaleClick(locale)}
                  >
                    <span>{t(`langTitle.${locale}`)}</span>
                    {locale === router.locale && <MonoIcon name="checkSign" />}
                  </NavSideLinkItemInner>
                ))}
            </>
          )}
        </NavSideLinks>

        <NavSideContactUs>
          <MailIcon />

          <div>
            <Caption>{t('header.nav.contact')}</Caption>
            <a href={contactUsMailto}>{supportEmail}</a>
          </div>
        </NavSideContactUs>
      </NavSideContainer>
    </NavWrapper>
  );
}

export default memo(Nav);
