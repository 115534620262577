import styled, { css, CSSProp } from 'styled-components';

import { smMedia, mdMedia, themeSize } from 'src/theme/utils';

export type AppContainerProps = {
  $size?: 'WIDE';
};

const getContainerWidth = (props: AppContainerProps): CSSProp => {
  const { $size } = props;

  if ($size === 'WIDE') {
    return css`
      width: 100%;
      padding-left: 0;
      padding-right: 0;

      ${smMedia(css`
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;
      `)}

      ${mdMedia(css`
        max-width: 100%;
      `)}
    `;
  }

  return css`
    width: 100%;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    ${smMedia(css`
      max-width: ${themeSize('tablet')};
      padding-left: 3rem;
      padding-right: 3rem;
    `)}

    ${mdMedia(css`
      max-width: ${themeSize('desktop')};
    `)}
  `;
};

export const AppContainer = styled.div<AppContainerProps>`
  ${getContainerWidth}
  margin-left: auto;
  margin-right: auto;
`;
