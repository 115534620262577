// eslint-disable-next-line import/prefer-default-export
export enum IconTypes {
  navMenu = 'navMenu',
  navCross = 'navCross',
  navCrossCircle = 'navCrossCircle',
  navCrossShadow = 'navCrossShadow',
  navLeft = 'navLeft',
  navRight = 'navRight',
  navLeftCircle = 'navLeftCircle',
  navRightCircle = 'navRightCircle',
  navSmallLeft = 'navSmallLeft',
  navSmallRight = 'navSmallRight',
  navArrowRightAlt = 'navArrowRightAlt',
  navUp = 'navUp',
  navDown = 'navDown',
  navOpen = 'navOpen',
  navOpenGreen = 'navOpenGreen',
  navOpenOrange = 'navOpenOrange',
  navOpenBlue = 'navOpenBlue',
  navOpenIndigo = 'navOpenIndigo',
  navOpenTheme = 'navOpenTheme',
  navClose = 'navClose',
  navCloseGreen = 'navCloseGreen',
  navCloseOrange = 'navCloseOrange',
  navCloseBlue = 'navCloseBlue',
  navCloseIndigo = 'navCloseIndigo',
  navCloseTheme = 'navCloseTheme',
  pbarGender = 'pbarGender',
  pbarGoal = 'pbarGoal',
  pbarDesiredWeight = 'pbarDesiredWeight',
  pbarMeasures = 'pbarMeasures',
  pbarPear = 'pbarPear',
  pbarTypicalDay = 'pbarTypicalDay',
  pbarStomachDiscomfort = 'pbarStomachDiscomfort',
  pbarStomachHeaviness = 'pbarStomachHeaviness',
  pbarTypicalRation = 'pbarTypicalRation',
  pbarIdealWeight = 'pbarIdealWeight',
  pbarEatingHabits = 'pbarEatingHabits',
  pbarFitness = 'pbarFitness',
  pbarEnergyLevel = 'pbarEnergyLevel',
  pbarSleep = 'pbarSleep',
  pbarWater = 'pbarWater',
  pbarCookingTime = 'pbarCookingTime',
  pbarRestrictions = 'pbarRestrictions',
  pbarVeggies = 'pbarVeggies',
  pbarProducts = 'pbarProducts',
  pbarMeat = 'pbarMeat',
  rbSelected = 'rbSelected',
  rbUnselected = 'rbUnselected',
  cmnError = 'cmnError',
  cmnFilled = 'cmnFilled',
  cmnFilledInverse = 'cmnFilledInverse',
  cmnFilledInverseTheme = 'cmnFilledInverseTheme',
  cmnFilledInverseOrange = 'cmnFilledInverseOrange',
  cmnHelpCircle = 'cmnHelpCircle',
  cmnPlusCircle = 'cmnPlusCircle',
  cmnImagePlaceholder = 'cmnImagePlaceholder',
  cmnCheck = 'cmnCheck',
  cmnCheckDefault = 'cmnCheckDefault',
  cmnCheckActive = 'cmnCheckActive',
  cmnCheckActiveGreen = 'cmnCheckActiveGreen',
  cmnCheckActiveOrange = 'cmnCheckActiveOrange',
  cmnCheckActiveBlue = 'cmnCheckActiveBlue',
  cmnCheckActiveIndigo = 'cmnCheckActiveIndigo',
  cmnEdit = 'cmnEdit',
  cmnSms = 'cmnSms',
  cmnChain = 'cmnChain',
  cmnYoutube = 'cmnYoutube',
  qzOffice = 'qzOffice',
  qzWalk = 'qzWalk',
  qzFarmer = 'qzqzFarmer',
  qzHome = 'qzHome',
  qzFire = 'qzFire',
  qzApple = 'qzApple',
  qzEnergy = 'qzEnergy',
  qzSandwich = 'qzSandwich',
  qzNight = 'qzNight',
  qzCake = 'qzCake',
  qzSoda = 'qzSoda',
  qzSalt = 'qzSalt',
  qzYoga = 'qzYoga',
  qzYes = 'qzYes',
  qzNo = 'qzNo',
  qzWarning = 'qzWarning',
  qzSteady = 'qzSteady',
  qzTired = 'qzTired',
  qzSleepy = 'qzSleepy',
  qzHandshake = 'qzHandshake',
  qzPotato = 'qzPotato',
  qzPeas = 'qzPeas',
  qzCouscous = 'qzCouscous',
  qzRice = 'qzRice',
  qzMushroom = 'qzMushroom',
  qzQuinoa = 'qzQuinoa',
  qzEgg = 'qzEgg',
  qzYogurt = 'qzYogurt',
  qzMilk = 'qzMilk',
  qzCottageCheese = 'qzCottageCheese',
  qzTofu = 'qzTofu',
  qzRolledOats = 'qzRolledOats',
  qzHummus = 'qzHummus',
  qzNoodles = 'qzNoodles',
  qzPlantMilk = 'qzPlantMilk',
  qzTurkey = 'qzTurkey',
  qzFish = 'qzFish',
  qzBeef = 'qzBeef',
  qzChicken = 'qzChicken',
  qzPork = 'qzPork',
  qzPear = 'qzPear',
  qzSquare = 'qzSquare',
  qzHourglass = 'qzHourglass',
  qzHeart = 'qzHeart',
  qzMoney = 'qzHouqzMoneyglass',
  qzExclamation = 'qzExclamation',
  navArrowRight = 'navArrowRight',
  navArrowRightActive = 'navArrowRightActive',
  cmnCheckGreen = 'cmnCheckGreen',
  broccoli = 'broccoli',
}
